import React from 'react'
import { I18n, Section } from '@front/volcanion'

import { Grid } from '@mui/material/'

import { AddressAutocompleteField, AutocompleteLabelButtons, AutocompleteFooter, TextField } from '@front/squirtle'

import AddressButtons from '../Button'
import AddressFooter from '../Footer'

const AddressInput = props => {
  const {
    poi_types,
    service,
    owner,
    load_type,
    prefix,
    onAddressResolve,
    getOptionIconName,
    commercial_formula,
    schedule_type,
  } = props

  return <>
    <Section name={prefix}>
      <Grid container alignItems='center'>
        <Grid item xs={12}>
          <AddressAutocompleteField
            search_id={`${prefix}_search`}
            name={'details'}
            label={I18n.t(`address.${prefix}`)}
            owner={owner}
            renderInputProps={{
              InputLabelProps: {
                style: { width: '200%', fontSize: 15, color: 'text.label' }
              }
            }}
            initialConfig={{
              search_id: `${prefix}_details`,
              includePOI: load_type !== 'package',
              load_type,
              requestOptions: {
                recentLimit: 4,
                favoriteLimit: 4,
                commercial_formula,
                direction_type: prefix,
                schedule_type,
              },
              watchers: [schedule_type, load_type, service, prefix, commercial_formula]
            }}
            noOptionsText={I18n.t('no_result')}
            required={load_type === 'package' || prefix === 'source'}
            resolveAddress
            onAddressResolve={onAddressResolve}
            getOptionIcon={getOptionIconName}
          >
            <AutocompleteLabelButtons>{load_type === 'user' && <AddressButtons poi_types={poi_types} />}</AutocompleteLabelButtons>
            <AutocompleteFooter><AddressFooter /></AutocompleteFooter>
          </AddressAutocompleteField >
        </Grid >
        <Grid item xs hidden> <TextField name='info' /></Grid>
      </Grid >
    </Section >
  </>
}

export default React.memo(AddressInput)
