import React, { useCallback } from 'react'
import { hooks, I18n } from '@front/volcanion'

import { Loader } from '@front/squirtle'

const withContainer = Component => props => {
  const [, { openDialog }] = hooks.useDialogs()

  const [reservationinfo_values, reservationinfo_id, poi_id, reservationinfo_type] = hooks.useFieldValues(['reservationinfo', 'reservationinfo.reservationinfo_id', 'source.details.poi_id', 'reservationinfo.reservationinfo_type'])
  const [reservationinfo, { isReady: isReservationInfoReady }] = hooks.useModel('reservationinfo', [reservationinfo_id], { single: true })
  const [poi, { isReady: isPoiReady }] = hooks.useModel('poi', [poi_id], { single: true })

  const onClick = useCallback(() => {
    openDialog('form_popup_poi', {
      title: `${I18n.t('info.additional.label', { count: -1 })}`,
      meeting_point: poi?.meeting_point,
      formProps: {
        initialValues: {
          name: reservationinfo_values?.name || undefined,
          origin: reservationinfo_values?.origin || undefined,
          reservationinfo_type,
        },
        initialState: { reservationinfo_id },
      }
    })
  }, [poi, reservationinfo_values, reservationinfo_id, reservationinfo_type])

  const mergedProps = {
    meeting_point: poi?.meeting_point,
    reservationinfo,
    reservationinfo_values,
    reservationinfo_type,
    onClick,
  }

  return <Loader isLoading={!isReservationInfoReady && !isPoiReady}>
    <Component {...mergedProps} />
  </Loader>
}

export default withContainer