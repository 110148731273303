import moment from 'moment'
class Callbacks {

  static afterChangeHandler(checkReservationInfo, reservationinfo, requestedAt, setFormState, threshold_RA, current_schedule) {
    return function afterChange(e) {
      const schedule_type = moment(e).utc().isAfter(moment().add(threshold_RA, 'minutes')) ? 'planned' : 'immediate'

      if (!!reservationinfo?.reservationinfo_id) {
        if (!!current_schedule && !!schedule_type && current_schedule !== schedule_type)
          checkReservationInfo(null, schedule_type)
        else {
          setFormState({ schedule_type, requestedAt })
        }
      } else {
        setFormState({ schedule_type, requestedAt })
        checkReservationInfo(null, schedule_type)
      }
      return
    }
  }
}

export default Callbacks
