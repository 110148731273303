import { I18n } from '@front/volcanion'
import moment from 'moment'

class Callbacks {

  static onSubmitPackageFormPopupHandler(packages, currentPackage, load_count, setFormValues, setFormState, openIncompatible, openDialog) {
    return async function onSubmitPackageFormPopup({ commercial_package }, extra, meta, { vehicle_count, requestedAt }) {
      const multiple = vehicle_count > 1
      const new_package = _.find(packages, ['commercial_package_id', commercial_package])

      if (!!currentPackage && commercial_package !== currentPackage) {
        const momentRequestedAt = !!requestedAt ? moment.utc(requestedAt) : moment.utc()
        const minimumDate = _.isFinite(new_package?.min_delay) ? moment().add(new_package?.min_delay, 'minutes').utc() : null
        const maximumDate = _.isFinite(new_package?.max_delay) ? moment().add(new_package?.max_delay, 'minutes').utc() : null
        const immediateAllowed = new_package?.immediate_order_enabled

        if ((!!maximumDate && moment(momentRequestedAt).isAfter(maximumDate, 'm')) || (!!minimumDate && !immediateAllowed && moment(momentRequestedAt).isBefore(minimumDate, 'm'))) {
          openDialog('information_invalid_datetime', {
            title: I18n.t('dialog.information_invalid_datetime.title'),
            _header: { _text: { fontSize: 20, color: 'primary' } },
            requestedAt,
            commercial_package_id: new_package.commercial_package_id,
          }, { requestedAt, commercial_package_id: new_package.commercial_package_id })
        }

        if (!!multiple) {
          setFormValues([
            { field: 'passenger_name', value: null },
            { field: 'passenger_number', value: null },
            { field: 'load_count', value: null }
          ])
          setFormState({ guest: false })
        } else {
          const max_load = new_package?.max_load
          if (!!max_load && max_load < load_count) {
            openIncompatible('incompatible_load_count_confirmation', max_load, commercial_package, I18n.t('load.incompatible.confirmation', { max: max_load, current: load_count }))
            return {}
          }
        }
        setFormValues([
          { field: 'commercial_package', value: commercial_package },
          { field: 'vehicle_count', value: vehicle_count }
        ])
      }
    }
  }
  static openIncompatibleHandler(openDialog, load_count, old_package) {
    return function openIncompatible(name, max_load, new_package, message) {
      openDialog(name, { message }, { max_load, current: load_count, new_package, old_package })
      return {}
    }
  }
  static onConfirmIncompatibleHandler(setFormValues) {
    return function onConfirmIncompatible(values, extra, meta, state) {
      const { max_load, new_package } = values
      setFormValues([
        { field: 'load_count', value: max_load },
        { field: 'commercial_package', value: new_package }
      ])
      return {}
    }
  }
  static onCancelIncompatibleHandler(setFormValues) {
    return function onCancelIncompatible(values, extra, meta, state) {
      const { current, old_package } = values
      setFormValues([
        { field: 'load_count', value: current },
        { field: 'commercial_package', value: old_package }
      ])
      return {}
    }
  }
  static openResumeOrderPopupHandler(openDialog) {
    return function openResumeOrderPopup(order_id) {
      openDialog('resume_order', { order_id })
      return {}
    }
  }

  static onSameAddressHandler(setFormValues) {
    return function onSameAddress() {
      setFormValues([
        { field: 'destination', value: undefined }
      ])
      return false
    }
  }

  static submitPoiAdditionalInfoFormHandler(setFormValues, poi, reservationinfo) {
    return async function submitPoiAdditionalInfoForm(values, extra, meta, state) {
      const arrival_trkey = values?.reservationinfo_type === 'arrival'
        ? reservationinfo?.arrival_trkey
        : poi?.meeting_point_trkey
      const arrival_translated = values?.reservationinfo_type === 'arrival'
        ? reservationinfo?.arrival_translated
        : poi?.meeting_point_translated
      const driver_instruction_trkey = values?.reservationinfo_type === 'arrival'
        ? reservationinfo?.arrival_trkey
        : poi?.driver_instruction_trkey
      setFormValues([
        { field: 'reservationinfo.reservationinfo_id', value: state?.reservationinfo_id || extra?.reservationinfo_id },
        { field: 'reservationinfo.name', value: values?.name },
        { field: 'reservationinfo.origin', value: values?.origin },
        { field: 'reservationinfo.arrival', value: arrival_translated },
        { field: 'reservationinfo.arrival_trkey', value: arrival_trkey },
        { field: 'reservationinfo.driver_instruction_trkey', value: driver_instruction_trkey },
        { field: 'reservationinfo.reservationinfo_type', value: values?.reservationinfo_type },
      ])
    }
  }
  static onCancelScheduleChangeHandler(setFormValues) {
    return async function onCancelScheduleChange(state) {
      setFormValues([{ field: 'requestedAt', value: state?.current_requestedAt }])
      return false
    }
  }
  static onConfirmScheduleChangeHandler(setFormValues, setFormState) {
    return async function onConfirmScheduleChange(state) {
      setFormValues([{ field: 'reservationinfo', value: undefined }])
      setFormState({ schedule_type: state?.new_schedule })
      return false
    }
  }
  static onCancelAddressChangeHandler(setFormValues, setSourceDetails, current_source) {
    return async function onCancelAddressChange(state) {
      setFormValues([{ field: 'source.details', value: current_source }])
      setSourceDetails(current_source)
      return false
    }
  }
  static onConfirmAddressChangeHandler(setFormValues, setSourceDetails) {
    return async function onConfirmAddressChange(state) {
      setFormValues([{ field: 'reservationinfo', value: undefined }])
      setSourceDetails(state?.new_source)
      return false
    }
  }
  static checkReservationInfoHandler(openDialog, searchPOI, formValues, formStateValues) {
    return async function checkReservationInfo(new_source, new_schedule) {
      const { reservationinfo, commercial_formula } = formValues || {}
      const { poi_id: current_poi, requestedAt: current_requestedAt, schedule_type: current_schedule } = formStateValues || {}

      const schedule = new_schedule || current_schedule
      const poi_id = new_source?.poi_id || current_poi

      if (!poi_id) {
        return
      }
      try {
        const pois = await searchPOI({ poi_id })
        const poi = _.head(pois)
        const authorized_cf_poi = _.find(_.get(_.head(pois), 'type.authorized_pois'), ['commercial_formula', commercial_formula])

        const { immediate_order = false, planned_order = false, pickup_at_transport = false } = authorized_cf_poi

        if (!!current_schedule && !!new_schedule && new_schedule !== current_schedule && !!reservationinfo?.reservationinfo_id) {
          openDialog('confirmation_reservation_invalid_schedule', {
            title: I18n.t('reservationinfo.invalid.title', { arrival: reservationinfo?.arrival }),
            description: I18n.t('reservationinfo.invalid.schedule', { arrival: reservationinfo?.arrival }),
          }, { new_schedule, current_requestedAt })
        }
        // else if (!!current_poi && new_source?.poi_id !== current_poi && !!reservationinfo?.reservationinfo_id) {
        // return true
        // openDialog('confirmation_reservation_invalid_address', {
        //   title: I18n.t('reservationinfo.invalid.title', { arrival: reservationinfo?.arrival }),
        //   description: I18n.t('reservationinfo.invalid.address', { arrival: reservationinfo?.arrival }),
        // }, { new_source })
        // }
        else if ((!immediate_order && schedule === 'immediate') || (!planned_order && schedule === 'planned'))
          openDialog('information_popup', {
            title: I18n.t('warning.label', { count: 1 }),
            description: I18n.t(`address.booking.${schedule}.disabled.poi`, { name: _.get(poi, 'type.name_trkey.fr') })
          })
        else if (schedule === 'planned') {
          if (!pickup_at_transport)
            openDialog('information.poi_meeting_point', { meeting: 'meeting_point' })
          else {
            openDialog('form_popup_poi', {
              title: `${I18n.t('info.additional.label', { count: -1 })}`,
              meeting_point: poi?.meeting_point,
              reservationinfo_id: poi?.type?.reservationinfo,
              formProps: {
                initialValues: {
                  name: reservationinfo?.name || undefined,
                  origin: reservationinfo?.origin || undefined,
                  arrival: reservationinfo?.arrival || poi?.meeting_point || undefined
                },
                initialState: { reservationinfo_id: poi?.type?.reservationinfo },
              }
            }, {
              reservationinfo_id: poi?.type?.reservationinfo
            })
          }
        }
        else if (!!new_source?.poi_id)
          openDialog('information_popup_poi', {
            title: `${I18n.t('info.additional.label', { count: -1 })}`,
            meeting_point: poi?.meeting_point
          })
        else
          return true
      } catch (err) {
        console.error(err)
      }
    }
  }
}

export default Callbacks
