
import React, { useCallback } from 'react'
import { CDNSVG } from '@front/volcanion'

import { IconButton, Grid, } from '@mui/material/'

const AddressButtons = ({ enableSource, isSourceTypeEnabled, isSourceEnabled, reset, poi_types }) => {
  const onAddressTypeClick = useCallback((source, type) => {
    if (isSourceEnabled(source, true) || isSourceTypeEnabled(type, source, true, true))
      reset()
    else
      enableSource(source, _.compact([type]), { strict: true })
  }, [enableSource, reset, isSourceEnabled, isSourceTypeEnabled])

  return (
    <Grid container justifyContent='flex-end'>
      <Grid container item xs={3} spacing={1} justifyContent='flex-end'>
        {_.map(poi_types, ({ poitype_id, icon, ...rest }, key) =>
          <Grid item xs={3} key={key}>
            <IconButton onClick={() => poitype_id === 'other' ? onAddressTypeClick('favorite') : onAddressTypeClick('poi', poitype_id)} sx={{ p: 0 }}>
              <CDNSVG category={'icons'} name={poitype_id === 'other' ? 'star' : icon} fill={(poitype_id === 'other' ? isSourceEnabled('favorite', true) : isSourceTypeEnabled('poi', poitype_id, true, true)) ? 'primary.main' : 'text.label'} />
            </IconButton>
          </Grid>
        )}
      </Grid>
    </Grid>
  )
}

export default React.memo(AddressButtons)
