import ValidationUtils from '@front/volcanion/utils/validation'

class Callbacks {

  static normalizePhoneHandler(CODE_INTERNATIONAL) {
    return function normalizePhone(value) {
      return ValidationUtils.parsePhone(value, CODE_INTERNATIONAL)
    }
  }

}

export default Callbacks