import React from 'react'
import { TableCell, Typography } from '@mui/material'

const defaultTableCellProps = {
  align: 'center',
  size: 'small'
}

const defaultTypographyProps = {
  sx: {
    fontSize: 15,
    textAlign: 'center',
    color: 'text.label'
  }
}

const Row = ({ row, isSelected, ...props }) => <>
  <TableCell {...defaultTableCellProps}>
    <Typography {...defaultTypographyProps}> {_.get(row, 'contact.last_name')}</Typography>
  </TableCell>
  <TableCell {...defaultTableCellProps}>
    <Typography {...defaultTypographyProps}> {_.get(row, 'contact.first_name')}</Typography>
  </TableCell>
  <TableCell {...defaultTableCellProps}>
    <Typography {...defaultTypographyProps}> {_.get(row, 'contact.type')}</Typography>
  </TableCell>
  <TableCell {...defaultTableCellProps}>
    <Typography {...defaultTypographyProps}> {_.get(row, 'contact.gsm')}</Typography>
  </TableCell>
  <TableCell {...defaultTableCellProps}>
    <Typography {...defaultTypographyProps}> {_.get(row, 'contact.tel')}</Typography>
  </TableCell>
  <TableCell {...defaultTableCellProps}>
    <Typography {...defaultTypographyProps}> {_.get(row, 'contact.email')}</Typography>
  </TableCell>
  <TableCell {...defaultTableCellProps}>
    <Typography {...defaultTypographyProps}> {_.get(row, 'contact.address')}</Typography>
  </TableCell>
</>

export default React.memo(Row)
