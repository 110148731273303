import React from 'react'
import { hooks } from '@front/volcanion'

const withContainer = Component => props => {
  const setFormValues = hooks.useFormChange()
  const [service] = hooks.useFieldValues(['service'])

  const mergedProps = {
    setFormValues,
    service
  }

  return <Component {...mergedProps} {...props} />
}

export default withContainer