import React, { useCallback, useMemo } from 'react'
import { hooks } from '@front/volcanion'

import { BillingUtils, HookUtils } from '@front/volcanion/utils'

import Callbacks from './callbacks'

const withContainer = Component => props => {
  const { openNotification } = hooks.useNotification()
  const isSubmitting = hooks.useFormStatus('isSubmitting')
  const requestSubmit = hooks.useFormFunction('requestSubmit')
  const [commercial_package_id, comment, ridemode_id] = hooks.useFieldValues(['commercial_package', 'comment', 'ridemode'])

  const [{ ridemode_validation }] = hooks.useFormState()
  const [ridemode] = hooks.useModel('ridemode', [ridemode_id], { single: true })
  const [destination_details] = hooks.useFormState('details.destination')
  const [cantBook] = hooks.useFormState('cantBook')
  const [commercial_package] = hooks.useModel('commercialpackage', [commercial_package_id], { single: true })

  const onRequestSubmit = useCallback(Callbacks.onRequestSubmitHandler(openNotification, requestSubmit, commercial_package, destination_details, cantBook, comment, ridemode), [openNotification, requestSubmit, commercial_package, destination_details, cantBook, comment, ridemode])

  const [estimation, estimation_state] = hooks.useSelectedEstimation(commercial_package_id)

  const estimation_type = estimation?.type === 'special' ? 'estimated' : estimation?.type || 'estimated'
  const price = BillingUtils.getEstimationDisplay(estimation?.details?.pricing, false, 1)
  const isLoading = HookUtils.getLoadingState(estimation_state)


  const disabledSubmitButton = !!HookUtils.getLoadingState(isLoading) || (!!ridemode_validation && ridemode_validation?.context?.reason !== 'missing_args')
  const mergedProps = {
    disabledSubmitButton,
    onRequestSubmit,
    price,
    estimation_type,
    isSubmitting,
    isLoading
  }

  return (
    <Component {...mergedProps} {...props} />
  )
}

export default withContainer