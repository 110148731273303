import { I18n } from '@front/volcanion'

class PopupCallbacks {
  static openOptionPopupHandler(openDialog) {
    return function openOptionPopup(driver_missing_options, vehicle_missing_options) {
      openDialog('option_confirmation', { driver_missing_options, vehicle_missing_options })
      return
    }
  }
  static cancelOptionPopupHandler(setFormValues, oldCp, driver_options, vehicle_options) {
    return function cancelOptionPopup(state, extra) {
      setFormValues([
        { field: 'commercial_package', value: oldCp },
        { field: 'vehicle_options', value: vehicle_options },
        { field: 'driver_options', value: driver_options }
      ])
      return
    }
  }
  static confirmOptionPopupHandler(setFormValues, setOldCp, commercial_package, driver_diff, vehicle_diff) {
    return function confirmOptionPopup() {
      setFormValues([
        { field: 'driver_options', value: driver_diff },
        { field: 'vehicle_options', value: vehicle_diff }
      ])
      setOldCp(commercial_package)
      return
    }
  }
}

export default PopupCallbacks
