import React, { useState, useCallback } from 'react'
import { I18n } from '@front/volcanion'

import { Box, Grid, IconButton, Typography, Icon, Button } from '@mui/material'

import { ModelCardGridField, ModelMenuSelectorField } from '@front/squirtle'
import { CardCustom } from '@front/squirtle/components/Card/component'

import ReactCardFlip from 'react-card-flip'

const FrontCard = ({ setFlip, flip, selectorProps, value, onChange, option, ...props }) => {
  const isSelected = selectorProps?.isOptionSelected(option)
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', pr: 2 }} >
      <Button
        size='small'
        pointerEvents='fill'
        onClick={() => onChange(value)}
        sx={{
          boxShadow: 3,
          borderRadius: 1,
          p: 1.5,
          textTransform: 'none'
        }}
        variant={!!isSelected ? 'contained' : 'text'}
      >
        <Typography align='center'>{option?.name_translated}</Typography>
      </Button>
    </Box>
  )
}

const BackCard = ({ setFlip, flip, option, ...props }) =>
  <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
    <Box sx={{ boxShadow: 6, borderRadius: 1 }}>
      <Box display='flex' sx={{ flexDirection: 'row-reverse', justifyContent: 'flex-start' }}>
        <IconButton sx={{ p: '3px' }} onClick={() => setFlip(!flip)} color='secondary'><Icon sx={{ fontSize: 15 }}>{'visibility'}</Icon></IconButton>
      </Box>
      <Box display='flex' sx={{ flexDirection: 'column', p: 1, pt: 0 }} alignItems='center'>
        <Typography variant='subtitle' align='center'> {option?.summary_translated}</Typography>
      </Box>
    </Box>
  </Box>

const Card = ({ option, ...props }) => {
  const [flip, setFlip] = useState(false)
  return (
    <Box>
      <ReactCardFlip isFlipped={flip} flipDirection="vertical">
        <FrontCard
          setFlip={setFlip}
          flip={flip}
          option={option}
          {...props}
        />
        <BackCard setFlip={setFlip} flip={flip} option={option} {...props} />
      </ReactCardFlip>
    </Box>

  )
}

const RidemodeSection = ({
  load_type,
  user,
  commercial_formula,
  service
}) =>
  <Grid item xs={12}>
    <ModelCardGridField
      name={'ridemode'}
      model_name={'ridemode'}
      allowNone={false}
      loadOnMount
      loadOnFocus={false}
      searchMode={'search'}
      model_func='getAuthorized'
      selectFirst
      margin={0}
      spacing={0}
      loaderProps={{ size: 50 }}
      getOptionsProps={(options, selectorProps) => ({ hide: options.length === 1 })}
      config={{
        sort: ['display_index ASC'],
        initial_filter: {},
        forced_filter: {
          load_type,
          user,
          commercial_formula,
          service
        },
        watchers: [
          load_type,
          user,
          commercial_formula,
          service
        ],
        validate_filter: useCallback(() => !!load_type, [load_type])
      }}
    >
      <CardCustom><Card /></CardCustom>
    </ModelCardGridField>
  </Grid>


export default React.memo(RidemodeSection)
