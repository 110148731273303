
class Callbacks {
  static filterToFormHandler() {
    return function filterToForm(filter) {
      return {
        ...filter
      }
    }
  }
  static filterToStateHandler(contract) {
    return function filterToState(state) {
      return {
      }
    }
  }
  static formToFilterHandler(company) {
    return function formToFilter(values, extra, meta, state) {
      return {
        company,
        ...values
      }
    }
  }
}

export default Callbacks
