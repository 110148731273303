import React, { useCallback } from 'react'

import { hooks } from '@front/volcanion'

const withContainer = Component => ({ row, ...props }) => {
  const companyservice_id = _.get(row, 'companyservice_id')
  const [archiveRecord] = hooks.useModelFunction('companyservice', 'modify')
  const onRemove = useCallback(() => archiveRecord({ companyservice_id, active: 0, deleted: 1 })
    , [archiveRecord])

  const mergedProps = {
    row,
    onRemove
  }

  return (
    <Component {...mergedProps} {...props} />
  )
}

export default withContainer
