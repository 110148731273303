import React from 'react'
import { I18n } from '@front/volcanion/'

import { Grid } from '@mui/material'

import { AddressAutocompleteField } from '@front/squirtle'

const FourthRow = props =>
  <Grid container item xs={12}>
    <Grid item xs>
      <AddressAutocompleteField
        name={'address'}
        label={I18n.t('address.address_home')}
        noOptionsText={I18n.t('no_result')}
        resolveAddress
        disabled
      />

    </Grid>
  </Grid>


export default React.memo(FourthRow)
