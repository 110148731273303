import React from 'react'
import { I18n, CDNImage, CDNSVG } from '@front/volcanion'

import { Box, Card, CardActionArea, CardContent, CardMedia, IconButton, Typography } from '@mui/material'

const SelectedCard = ({
  client_type_icon,
  name_translated,
  title_translated,
  max_load,
  hasPackage,
  openPackageFormPopup,
  load_type,
  errorText,
}) =>
  <Card raised sx={{ bgcolor: 'extraWhite' }}>
    <CardActionArea disabled={!hasPackage} onClick={(openPackageFormPopup)}>
      <CardMedia>
        <Box sx={{ display: !hasPackage ? 'none' : 'flex', justifyContent: 'flex-end' }}>
          <IconButton color={'primary'} disabled sx={{ m: 0, p: '3px' }} >
            <CDNSVG name='edit' fill='primary.main' category={'icons'} />
          </IconButton>
        </Box>
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', paddingLeft: 5, paddingRight: 5 }}>
          <CDNImage category='packages' name={client_type_icon} />
        </Box>
      </CardMedia>
      <CardContent sx={{ pl: '12px', pr: '12px', pb: '12px', pt: '3px' }}>
        <Box sx={{ color: 'text.label' }}>
          <Box sx={{ display: 'flex', justifyContent: 'center' }}>
            <Typography sx={{ textAlign: 'center', fontSize: 'h6.fontSize', letterSpacing: 3, color: 'primary.main', mr: 1 }}>{name_translated}</Typography>
          </Box>
          <Box sx={{ alignItems: 'center', display: !hasPackage ? 'none' : 'block' }}>
            <Typography sx={{ textAlign: 'center', ml: '3px', fontSize: 13 }}> {title_translated}</Typography>
          </Box>
          <Box sx={{ alignItems: 'center', display: (!hasPackage || load_type === 'package') ? 'none' : 'flex' }}>
            <Typography sx={{ fontWeight: 800, fontSize: 'caption.fontSize', mr: '4px' }}>{I18n.t('package.capacity.label')}</Typography>
            <Typography sx={{ ml: '1px', fontSize: 'caption.fontSize' }}> {I18n.t('package.capacity.text_user', { start: 1, end: max_load || 3 })}</Typography>
          </Box>
          <Box sx={{ alignItems: 'center', display: !errorText ? 'none' : 'flex', color: 'red' }}>
            <Typography sx={{ ml: '1px', fontSize: 'caption.fontSize' }}> {errorText}</Typography>
          </Box>
        </Box>
      </CardContent>

    </CardActionArea>
  </Card >

export default SelectedCard
