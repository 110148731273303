import React from 'react'

import { hooks } from '@front/volcanion'

const withContainer = Component => props => {
  const navigate = hooks.useNavigate()
  const [record_id] = hooks.useFormState('record_id')
  const [selectecCompany] = hooks.useGlobalState('selectedCompany')

  const administration_level = hooks.useAdministrationLevelUser(selectecCompany, record_id)

  const mergedProps = {
    allowCreate: _.includes(['company', 'contract'], administration_level),
    administration_level,
    navigate,
    record_id
  }

  return (
    <Component {...mergedProps} />
  )
}

export default withContainer
