import React from 'react'
import { hooks } from '@front/volcanion'

const withContainer = Component => props => {
  const [ridemode_id] = hooks.useFieldValues(['ridemode'])
  const options = hooks.useRidemodeDurationOptions(ridemode_id)

  const mergedProps = {
    options
  }

  return (
    <Component {...mergedProps} />
  )
}
export default withContainer
