import React, { useMemo } from 'react'
import { I18n, hooks } from '@front/volcanion'
import moment from 'moment'
import { Box, Chip, TableCell, Typography } from '@mui/material'

import GeoUtils from "@front/volcanion/utils/geo"
import TimeUtils from '@front/volcanion/utils/time'

const defaultTableCellProps = {
  align: 'center',
  size: 'small',
  sx: { border: 0 }
}

const defaultTypographyProps = {
  fontSize: 15,
  textAlign: 'center',
  color: 'text.label'
}

const Row = ({ row, isSelected, ...props }) => {

  const status = _.get(row, 'status')
  const parsed_status = hooks.useOrderStatus(row, { translated: true })
  const transport_status = _.get(row, 'assigned_transport.status')

  const formatted_transport_status = useMemo(() => {
    switch (status) {
      case 'attributed':
        if (_.includes(['at_src', 'on_board', 'in_route', 'at_dest', 'off_board'], transport_status))
          return I18n.t(`order.status.${transport_status}`)
        else if (_.includes(['started'], transport_status))
          return I18n.t('order.status.started_short')
      case 'standby':
        return I18n.t('order.status.standby_alter')
      default:
        return parsed_status
    }
  }, [status, parsed_status, transport_status])

  const eta =
    _.includes(['started'], transport_status) && !!_.get(row, 'current_target.eta.time')
      ? I18n.t('order.status.started', { eta: TimeUtils.secondsToTime(moment(_.get(row, 'current_target.eta.time')).diff(moment(), 'seconds')) })
      : '-'

  const passenger = _.get(_.head(_.get(row, 'loads')), 'src_contact') || '-'

  const [formatted_vehicle] = hooks.useModel('vehicle', [_.get(row, 'assigned_transport.vehicle')], {
    populate: ['info.model.brand'],
    single: true,
    mutator: elem => _.join([_.get(elem, 'model.name'), _.get(elem, 'info.model.brand.name'), _.get(elem, 'info.color')], ' ')
  })

  return <>
    <TableCell {...defaultTableCellProps}>
      <Typography sx={{ ...defaultTypographyProps, textAlign: 'start', fontWeight: 600, whiteSpace: 'nowrap' }}>  {passenger}</Typography>
    </TableCell>

    <TableCell {...defaultTableCellProps}>
      <Box sx={{ display: 'flex', justifyContent: 'center' }}>
        <Chip size='small' sx={{ color: hooks.useOrderStatusColor(row), borderColor: hooks.useOrderStatusColor(row) }} label={formatted_transport_status} variant="outlined" />
      </Box>
    </TableCell>

    <TableCell {...defaultTableCellProps}>
      <Typography sx={defaultTypographyProps}> {GeoUtils.format(_.get(row, 'destination')) || '-'} </Typography>
    </TableCell>

    <TableCell {...defaultTableCellProps}>
      <Typography sx={defaultTypographyProps}> {_.get(row, 'assigned_transport.driver.info.first_name') || '-'} </Typography>
    </TableCell>

    <TableCell {...defaultTableCellProps}>
      <Typography sx={{ ...defaultTypographyProps, whiteSpace: 'nowrap' }}> {formatted_vehicle || '-'} </Typography>
    </TableCell>

    <TableCell {...defaultTableCellProps}>
      <Typography sx={{ ...defaultTypographyProps, mr: 1, fontWeight: 200 }}> {moment(_.get(row, 'requestedAt')).format('HH:mm')} </Typography>
    </TableCell>

    <TableCell {...defaultTableCellProps}>
      <Typography sx={{ ...defaultTypographyProps, mr: 1, fontWeight: 200 }}> {eta} </Typography>
    </TableCell>
  </ >
}

export default React.memo(Row)
