import React from 'react'
import { hooks } from '@front/volcanion/'

import { Loader } from '@front/squirtle'

import moment from 'moment'
import { HookUtils } from '@front/volcanion/utils'
import { TimeUtils } from '@front/volcanion/utils'

const withContainer = Component => ({ record_id, requestedAt }) => {
  const [traffic, state] = hooks.useModel('trafficalert', [record_id], { single: true })

  const mergedProps = {
    reason: traffic?.warning_translated,
    margin: TimeUtils.secondsToTime(traffic?.margin * 60, null, { hide_seconds: true }),
    requestedAt: moment(requestedAt).format('[le] DD/MM/YYYY [entre] HH[h]mm'),
    margin_time: moment(requestedAt).add(traffic?.margin, 'minutes').format('HH[h]mm[min]')
  }

  return (
    <Loader isLoading={HookUtils.getLoadingState(state)}>
      <Component {...mergedProps} />
    </Loader>
  )
}

export default withContainer