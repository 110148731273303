import React, { useCallback, useEffect } from 'react'
import { hooks } from '@front/volcanion'

import Utils from '@front/volcanion/utils'
import GeoUtils from '@front/volcanion/utils/geo'

import Callbacks from './callbacks'

const withContainer = Component => props => {
  const setFormValues = hooks.useFormChange()
  const [destination_details] = hooks.useFormState('details.destination')
  const [destination, fixed_price, payment_type] = hooks.useFieldValues(['destination', 'fixed_price', 'payment_type'])
  const [selectedPaymentType] = hooks.useModel('paymenttype', [payment_type], { single: true })

  const onFixedPriceChange = useCallback(Callbacks.onFixedPriceChangeHandler(setFormValues), [setFormValues])

  const isValidPaymentType = selectedPaymentType?.type !== 'immediate'
  const isDisabledFixedPrice = _.isEmpty(_.get(destination, 'details')) || !isValidPaymentType
  const hasDestination = !_.isEmpty(destination_details)

  useEffect(() => {
    if (_.isEmpty(destination_details) && !!fixed_price) {
      setFormValues([{ field: 'fixed_price', value: false }])
    }
  }, [Utils.selectFirstKey(destination_details, GeoUtils.getAddressKeys())])

  const mergedProps = {
    isDisabledFixedPrice,
    hasDestination,
    isValidPaymentType,
    fixed_price,
    onFixedPriceChange,
  }

  return <Component {...mergedProps} {...props} />
}

export default withContainer