import React from 'react'
import { CDNSVG, I18n } from '@front/volcanion'

import { Grid, Box } from '@mui/material'

import AccountSelector from '@onix/elements/AccountSelector'

const EditIcon = props =>
  <CDNSVG name='edit' fill='primary.main' category={'icons'} {...props} />

const AccountSection = ({ user_accounts, disabled, afterChange }) =>
  <Grid item xs={7}>
    <Box hidden={_.isEmpty(user_accounts)} sx={{
      '&:hover': !disabled && { backgroundColor: 'white' },
      bgcolor: 'extraWhite',
      p: '3px',
      pr: 1,
      pl: 1,
      borderRadius: '4px',
      border: '1px solid',
      borderColor: 'rgba(61, 66, 94, 0.5)',
      width: 1
    }}
    >
      <AccountSelector
        name={'service'}
        disableUnderline
        disabled={disabled}
        afterChange={afterChange}
        selectProps={{ IconComponent: !!disabled ? null : EditIcon }}
        getOptionIcon={option => 'work'}
        getOptionIconProps={option => ({ size: 27, base: 'material-icons' })}
        sx={{ '.MuiSelect-icon': { color: 'primary.main' } }}
        selectFirst
        fullWidth
        variant='standard'
        hideIfEmpty
        label={null}
        config={{
          mutator: (companyuserservice) => ({
            ...companyuserservice,
            label: I18n.t('company.account', { prefix: ':', name: companyuserservice?.companyservice?.contract?.name, extra: companyuserservice?.companyservice?.name }),
            value: _.get(companyuserservice, 'companyservice.companyservice_id'),
          }),
          sort: [
            'companyservice.contract.formula.display_index ASC'
          ],
          search_id: 'user_accounts'
        }}
      />
    </Box>
  </Grid>

export default React.memo(AccountSection)
