import React, { useCallback } from 'react'
import { I18n, hooks } from '@front/volcanion'

import { TableHeader, TableRow, TableRowActions, SearchFrame, SearchForm, SearchTable, SearchCreateButton, FormContent } from '@front/squirtle'
import { Box, Typography, Button } from '@mui/material'
import AddIcon from '@mui/icons-material/Add'

import Callbacks from './callbacks'

import Form from './Form'
import Header from './Header'
import Row from './Row'
import ButtonRowAction from './ButtonRow'

const AddService = ({ administration_level }) => {
  const [, { openDialog }] = hooks.useDialogs()
  const [record_id] = hooks.useFormState('record_id')
  const [selectedCompany] = hooks.useGlobalState('selectedCompany')
  const [auth_account] = hooks.useFieldValues(['auth_account'])
  return <Box m={2} width={1} display='flex' justifyContent='space-between' alignItems='center'>
    <Typography sx={{ color: 'primary.main', fontSize: 21 }}> {I18n.t('companyservice.associated')}</Typography>
    <Button
      startIcon={<AddIcon />}
      variant='outlined'
      sx={{ textTransform: 'unset' }}
      onClick={() => openDialog('generate_service', {
        contract: record_id,
        company: selectedCompany,
        administration_level,
        isReadOnly: false,
        auth_account,
      })}>
      {I18n.t('companyservice.create')}
    </Button>
  </Box>
}

const ServicesSection = ({ record_id, navigate, allowCreate, administration_level }) => {
  const filterToForm = useCallback(Callbacks.filterToFormHandler(), [])
  const formToFilter = useCallback(Callbacks.formToFilterHandler(), [])
  const filterToState = useCallback(Callbacks.filterToStateHandler(record_id), [record_id])
  const handleRowClick = useCallback((rowId, row) => navigate(`/admin/service/${rowId}`), [])

  return (
    <Box width={1}>
      {!!allowCreate && <SearchCreateButton><AddService administration_level={administration_level} /></SearchCreateButton>}
      <SearchFrame
        model_name={'companyservice'}
        populate={['contact']}
        watch_type={'result'}
        allowCreate={false}
      >
        <SearchForm
          filterToForm={filterToForm}
          formToFilter={formToFilter}
          filterToState={filterToState}
          submitOnMount
          disableButton
        >
          <FormContent><Form /></FormContent>
        </SearchForm>
        <SearchTable
          tableProps={{
            sx: { border: 1, boderColor: 'primary.main' },
            enableRowClick: true,
            enablePagination: false,
            enableRowActions: !!allowCreate,
            onRowClick: handleRowClick
          }}
        >
          <TableHeader><Header /></TableHeader>
          <TableRow><Row /></TableRow>
          <TableRowActions><ButtonRowAction icon='delete' /></TableRowActions>
        </SearchTable>
      </SearchFrame >
    </Box>
  )
}

export default React.memo(ServicesSection)
