import React from 'react'

import { Chip, Box, Grid } from '@mui/material'
import { LocalPhone, AlternateEmail, Room } from '@mui/icons-material'

import { TextComponent } from '@front/squirtle'

const CompanyInfo = ({ email, gsm, address, ...props }) => <Box sx={{ width: "100%" }}>
  <Grid container justifyContent='center' rowSpacing={2}>
    <Grid item xs>
      <Box sx={{ display: 'flex', justifyContent: 'center' }}>
        <Chip
          icon={<LocalPhone />}
          color={'primary'}
          sx={{ mr: '2px' }}
          label={gsm}
          variant={'outlined'}
          size={'small'}
        />
      </Box>
    </Grid>
    <Grid item xs>
      <Box sx={{ display: 'flex', justifyContent: 'center' }}>
        <Chip
          icon={<AlternateEmail />}
          color={'primary'}
          sx={{ ml: '2px' }}
          label={email}
          variant={'outlined'}
          size={'small'}
        />
      </Box>
    </Grid>
    <Grid item xs>
      <Box sx={{ display: 'flex', justifyContent: 'center' }}>
        <Chip
          icon={<Room />}
          color={'primary'}
          label={address}
          variant={'outlined'}
          size={'small'}
        />
      </Box>
    </Grid>
  </Grid>
</Box >


export default React.memo(CompanyInfo)
