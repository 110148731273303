import React from 'react'
import { I18n } from '@front/volcanion'

import { Box, Grid, Typography } from '@mui/material'

const InformationRow = props =>
  <Grid item xs={12}>
    <Typography sx={{ fontSize: 15, color: 'text.label', fontWeight: 200, letterSpacing: 1.5 }}>{I18n.t('option.missing.sublabel')}</Typography>
  </Grid>

const OptionRow = ({ label, value, hidden, ...props }) =>
  <Grid item xs={12} hidden={hidden}>
    <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
      <Typography sx={{ fontSize: 15, fontWeight: 200, color: 'text.label', mr: 1, letterSpacing: 1.5 }}> {label} </Typography>
      <Typography color={'primary'} sx={{ fontSize: 15, fontWeight: 600 }}> {value}</Typography>
    </Box>
  </Grid>

const OptionDialog = ({ driver_ots_name, vehicle_opts_name, ...props }) => <>
  <Grid container rowSpacing={1}>
    <InformationRow />
    <OptionRow
      label={`${I18n.t('option.useroption.label', { count: 1 })} :`}
      value={driver_ots_name}
      hidden={_.isEmpty(driver_ots_name)}
    />
    <OptionRow
      label={`${I18n.t('option.vehicleoption.label', { count: 1 })} :`}
      value={vehicle_opts_name}
      hidden={_.isEmpty(vehicle_opts_name)}
    />
  </Grid>
</>
export default React.memo(OptionDialog)
