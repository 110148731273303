import React, { useCallback } from 'react'
import { hooks, I18n } from '@front/volcanion'
import { TableRow, TableHeader, SearchFrame, SearchForm, SearchTable, FormContent } from '@front/squirtle'

import Callbacks from './callbacks'

import Header from './Header'
import Row from './Row'
import Form from './Form'

const ContactsFrame = props => {
  const navigate = hooks.useNavigate()
  const [selectedCompany] = hooks.useGlobalState('selectedCompany')
  const filterToForm = useCallback(Callbacks.filterToFormHandler(), [])
  const formToFilter = useCallback(Callbacks.formToFilterHandler(selectedCompany), [selectedCompany])
  const filterToState = useCallback(Callbacks.filterToStateHandler(), [])

  const handleRowClick = useCallback((rowId, row) => navigate(`/admin/contact/${_.get(row, 'companycontact_id')}`), [])

  return <>
    <SearchFrame
      model_name={'companycontact'}
      populate={['contact']}
      createButtonProps={{
        label: I18n.t('contact.add'),
        onClick: () => navigate('/admin/contact/create')
      }}
    >
      <SearchForm
        filterToForm={filterToForm}
        formToFilter={formToFilter}
        filterToState={filterToState}
        getButtonStyleProps={() => ({ mt: 2, mb: 4 })}
        submitOnMount
        submitWatchers={[selectedCompany]}
      >
        <FormContent><Form /></FormContent>
      </SearchForm>
      <SearchTable
        tableProps={{
          enablePagination: false,
          onRowClick: handleRowClick
        }}
      >
        <TableHeader><Header /></TableHeader>
        <TableRow><Row /></TableRow>
      </SearchTable>
    </SearchFrame >
  </>
}

export default React.memo(ContactsFrame)
