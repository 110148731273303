import React, { useMemo } from 'react'
import { I18n } from '@front/volcanion'

import { TableCell, Typography } from '@mui/material'

import FormatUtils from '@front/squirtle/utils/format'


const defaultTableCellProps = {
  align: 'center',
  size: 'small'
}

const defaultTypographyProps = {
  sx: {
    fontSize: 15,
    textAlign: 'center',
    color: 'text.label'
  }
}

const Row = ({ row, isSelected, ...props }) => {

  const status = useMemo(() =>
    I18n.t(`status.${!!_.get(row, 'deleted')
      ? 'archived'
      : !!_.get(row, 'active')
        ? 'active'
        : 'inactive'}`)
    , [])

  const statusColor = useMemo(() =>
    !!_.get(row, 'deleted')
      ? 'orange'
      : !!_.get(row, 'active')
        ? 'green'
        : 'vermillion'
    , [])

  return <>
    <TableCell {...defaultTableCellProps}>
      <Typography {...defaultTypographyProps}> {_.get(row, 'name') || '-'}</Typography>
    </TableCell>
    <TableCell {...defaultTableCellProps}>
      <Typography {...defaultTypographyProps}> {_.get(row, 'client_id') || '-'}</Typography>
    </TableCell>
    <TableCell {...defaultTableCellProps}>
      <Typography {...defaultTypographyProps}> {_.get(row, 'formula.name') || '-'}</Typography>
    </TableCell>
    <TableCell {...defaultTableCellProps}>
      <Typography {...defaultTypographyProps}> {_.get(row, 'contact.address') || '-'}</Typography>
    </TableCell>
    <TableCell {...defaultTableCellProps}>
      <Typography {..._.merge({}, defaultTypographyProps, { sx: { color: statusColor } })}> {status || '-'} </Typography>
    </TableCell>
    <TableCell {...defaultTableCellProps}>
      {FormatUtils.formatDateBackToFront(_.get(row, 'availableSince'), 'DD/MM/YYYY') || '-'}
    </TableCell>
    <TableCell {...defaultTableCellProps}>
      {FormatUtils.formatDateBackToFront(_.get(row, 'availableUntil'), 'DD/MM/YYYY') || '-'}
    </TableCell>
  </>
}

export default React.memo(Row)
