import React from 'react'

import { hooks } from '@front/volcanion'

const withContainer = Component => props => {
  const [user] = hooks.useFormState('user_id')

  const [load_type, commercial_formula, service] = hooks.useFieldValues(['load_type', 'commercial_formula', 'service'])

  const mergedProps = {
    load_type,
    user,
    commercial_formula,
    service,
  }

  return (
    <Component {...mergedProps} {...props} />
  )
}

export default withContainer
