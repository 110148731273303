import React, { useCallback, useMemo } from 'react'
import { hooks } from '@front/volcanion'

import Callbacks from './callbacks'

const withContainer = Component => ({ prefix, checkReservationInfo, ...props }) => {
  const [load_type, reservationinfo, service, commercial_formula, source, destination] = hooks.useFieldValues(['load_type', 'reservationinfo', 'service', 'commercial_formula', 'source', 'destination'])
  const [cf] = hooks.useModel('commercialformula', [commercial_formula], { single: true, populate: ['google_options', 'authorized_pois.poi_type'] })

  const [, { openDialog }] = hooks.useDialogs()
  const setFormValues = hooks.useFormChange()
  const [{ schedule_type, user_id }] = hooks.useFormState()

  const [addressDetails, setAddressDetails] = hooks.useFormState(`details.${prefix}`)

  const current_poi = _.get(addressDetails, 'poi_id')
  const poi_types = useMemo(Callbacks.getPoiTypeHandler(prefix, cf, schedule_type), [prefix, cf, schedule_type])
  const owner = !!service ? undefined : user_id

  const onSourceAddressResolve = useCallback(Callbacks.onSourceAddressResolveHandler(setAddressDetails, checkReservationInfo, reservationinfo, current_poi, setFormValues, openDialog), [setAddressDetails, checkReservationInfo, reservationinfo, current_poi, setFormValues, openDialog])
  const onAddressResolve = useCallback(Callbacks.onAddressResolveHandler(source, destination, prefix, openDialog, setAddressDetails, onSourceAddressResolve), [source, destination, prefix, openDialog, setAddressDetails, onSourceAddressResolve])
  const getOptionIconName = useCallback(Callbacks.getOptionIconNameHandler(), [])

  const mergedProps = {
    owner,
    poi_types,
    prefix,
    service,
    load_type,
    schedule_type,
    commercial_formula,
    onAddressResolve,
    getOptionIconName
  }

  return (
    <Component {...mergedProps} {...props} />
  )
}
export default withContainer