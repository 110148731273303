import React, { useEffect } from 'react'
import { I18n, hooks } from '@front/volcanion'

const withContainer = Component => ({ tab, ...props }) => {
  const isMounted = hooks.useFormStatus('isMounted')
  const requestSubmit = hooks.useFormFunction('requestSubmit')
  const B2B_ENABLED = hooks.useRelayFeature('B2B_ENABLED')

  const options = _.compact([{
    label: I18n.t('type.perso'),
    value: 'b2c',
  }, !!B2B_ENABLED && {
    label: I18n.t('type.pro'),
    value: 'b2b',
  }])

  const order_status = _.compact([{
    label: I18n.t('order.status.complete'),
    value: 'complete'
  }, {
    label: I18n.t('order.status.canceled'),
    value: 'canceled'
  }])


  useEffect(() => {
    !!isMounted && requestSubmit()
  }, [tab])


  const mergedProps = {
    requestSubmit,
    order_status,
    options,
    tab
  }

  return (
    <Component {...mergedProps} />
  )
}

export default withContainer
