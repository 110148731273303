import React, { useCallback, useEffect } from 'react'
import { hooks } from '@front/volcanion'

import { Loader } from '@front/squirtle'
import Callbacks from './callbacks'
import { HookUtils } from '@front/volcanion/utils'

const withContainer = Component => props => {
  const setFormValues = hooks.useFormChange()
  const [, setFormState] = hooks.useFormState()
  const [user, user_state] = hooks.useActiveUser({ populate: ['info', 'customerinfo', 'auth', 'user_companies.services.contract', 'user_companies.contact'] })
  const user_accounts_state = hooks.useSearchListenerStatus('companyuserservices', 'user_accounts')
  const user_accounts = hooks.useSearchListenerResult('companyuserservices', 'user_accounts')

  const disabled = _.isEmpty(user_accounts)
  const service = _.head(_.map(user_accounts, (companyuserservice) => _.get(companyuserservice, 'companyservice.companyservice_id')))
  const isLoading = HookUtils.getLoadingState(user_state)
  const isLoadingUserAccounts = HookUtils.getLoadingState(user_accounts_state)

  const afterChange = useCallback(Callbacks.afterChangeHandler(setFormValues, setFormState, user), [setFormValues, setFormState, user])

  useEffect(() => {
    !isLoadingUserAccounts && !!service && setFormValues([{ field: 'service', value: service }])
  }, [isLoadingUserAccounts, service])

  const mergedProps = {
    user_accounts,
    disabled,
    afterChange
  }

  return (
    <Loader isLoading={isLoading}>
      <Component {...mergedProps} {...props} />
    </Loader>
  )
}
export default withContainer
