import React from 'react'
import { I18n } from '@front/volcanion'

import { Box, Grid, Typography } from '@mui/material'
import { TextField } from '@front/squirtle'

const InputLabelProps = {
  style: {
    fontSize: 22,
    letterSpacing: 1.5
  }
}

const Title = ({ prefix, ...props }) =>
  <Grid item xs={12}>
    <Box sx={{ borderLeft: 4, borderColor: 'primary.main', mt: 1, }}>
      <Typography sx={{ fontSize: 'h5.fontSize', color: 'primary.main', p: 2, fontWeight: 300 }}>
        {prefix === 'source' ? I18n.t('address.pickup.label', { count: 1 }) : I18n.t('address.dropoff.label', { count: 1 })}
      </Typography>
    </Box>
  </Grid>


const PassengerPopup = ({ prefix, normalizePhone, ...props }) =>
  <Grid container spacing={2} rowSpacing={1.5} justifyContent='flex-end'>
    <Title prefix={prefix} />
    <Grid item container xs={12} justifyContent='center'>
      <Grid item container xs={6} rowSpacing={2}>
        <Grid item xs={12}>
          <TextField
            name={'resident_name'}
            label={I18n.t('address.resident_name')}
            InputLabelProps={InputLabelProps}
            required
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            name={'passenger_name'}
            label={I18n.t('contact.label', { count: 1 })}
            InputLabelProps={InputLabelProps}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            name={'passenger_number'}
            label={I18n.t('gsm.label', { count: 1 })}
            InputLabelProps={InputLabelProps}
            normalize={normalizePhone}
          />
        </Grid>
      </Grid >
    </Grid>
  </Grid>


export default React.memo(PassengerPopup)
