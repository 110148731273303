import React, { useCallback } from 'react'
import { I18n } from '@front/volcanion'

import { Box, Grid } from '@mui/material'
import { TextField, ModelAutocompleteField } from '@front/squirtle'

const RefSection = ({ service, manual_allowed, customerrefoption_id, required, ...props }) =>
  <Grid item xs={12} >
    <Box sx={{ '&:hover': { backgroundColor: 'white' }, bgcolor: 'extraWhite', p: '3px', pr: 1, pl: 1, borderRadius: '4px', border: '1px solid', borderColor: 'rgba(61, 66, 94, 0.5)' }}>
      <Grid container>
        <Grid item xs hidden={!service}>
          {!!service && <ModelAutocompleteField
            name={'customer_ref'}
            model_name={'customerref'}
            labelKeys={['client_id']}
            selectKeys={['client_id']}
            searchMode={'search'}
            searchQuery={'startsWith'}
            autocompleteKey={'client_id'}
            loadOnFocus
            loadOnMount
            creatable={!!manual_allowed}
            selectFirst={false}
            selectCreatableLabel={true}
            selectSingleOption={!manual_allowed}
            debounceTime={400}
            config={{
              forced_filter: {
                client_id: { '!': null },
                parent: customerrefoption_id
              },
              validate_filter: useCallback(() => !!customerrefoption_id, [customerrefoption_id]),
              watchers: [customerrefoption_id]
            }}
            required={required}
            disableUnderline
            placeholder={I18n.t('ref.billing.label', { count: 1 })}
          />
          }
        </Grid>
        <Grid item xs hidden={!!service}>
          {!service && <TextField
            name={'customer_ref'}
            emptyValue='-'
            InputProps={{
              disableUnderline: true,
              inputProps: {
                style: {
                  margin: '2px',
                  fontSize: 15,
                  color: 'text.label'
                }
              }
            }}
            required={required}
            placeholder={I18n.t(`ref.billing.label.${required ? 'required' : 'one'}`)}
          />}
        </Grid>
      </Grid>
    </Box>
  </Grid>


export default React.memo(RefSection)
