import React from 'react'
import { I18n } from '@front/volcanion'

import moment from 'moment'

const withContainer = Component => ({ requestedAt, schedule_type, ...rest }) => {
  const order_type = !!schedule_type && I18n.t(`order.${schedule_type}.longLabel`, { count: 1 })
  const requestedAtFormatted = _.toLower(moment.utc(requestedAt).local().calendar(null, I18n.t(`voc.calendar_complete`)))

  const mergedProps = {
    order_type,
    requestedAtFormatted
  }

  return (
    <Component {...mergedProps} />
  )
}

export default withContainer