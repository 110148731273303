import React from 'react'
import { I18n, CDNSVG } from '@front/volcanion'

import { Grid, Icon, Button, Box, Typography } from '@mui/material'
import { FormDialog, DialogContent } from '@front/squirtle'

import AddressInfoPopup from '../../Popup/AddressInfo'

const Title = ({ title, ...props }) =>
  <Grid item xs={12}>
    <Typography color={'primary'}>  {title} </Typography>
  </Grid>

const Row1 = ({ iconColor, resident_name = I18n.t('to_inform'), ...props }) =>
  <Grid container item xs={12}>
    <Grid item xs>
      <Icon sx={{ fontSize: 25 }} color={iconColor}> {'flag'} </Icon>
    </Grid>
    <Grid item xs={10}>
      <Box sx={{ display: 'flex', justifyContent: 'center' }}>
        <Icon color={'primary'} sx={{ fontSize: 21 }}> {'apartment'} </Icon>
        <Typography sx={{ color: 'text.label', ml: '2px', fontSize: 13 }}>  {resident_name} </Typography>
      </Box>
    </Grid>
    <Grid item xs>
      <CDNSVG fill={'primary.main'} category={'icons'} />
    </Grid>
  </Grid>

const Row2 = ({ passenger_name = I18n.t('to_inform'), passenger_number = I18n.t('to_inform'), ...props }) =>
  <Grid item xs={12}>
    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
      <Icon color={'primary'} sx={{ fontSize: 21 }}> {'person'} </Icon>
      <Typography sx={{ color: 'text.label', ml: '2px', mr: 1, fontSize: 13 }}>  {passenger_name} </Typography>
      <Icon color={'primary'} sx={{ fontSize: 21 }}> {'phone'} </Icon>
      <Typography sx={{ color: 'text.label', ml: '2px', fontSize: 13 }}>  {passenger_number} </Typography>
    </Box>
  </Grid>

const InfoSection = props => {
  const {
    title,
    popupName,
    iconColor,
    resident_name,
    passenger_name,
    passenger_number,
    openAddressInfoPopup,
    submitAddressInfoPopup,
    ...rest
  } = props

  return <>
    <FormDialog
      name={popupName}
      formProps={{
        onSubmit: submitAddressInfoPopup,
        initialValues: {
          resident_name,
          passenger_name,
          passenger_number
        }
      }}
    >
      <DialogContent> <AddressInfoPopup /> </DialogContent>
    </FormDialog>

    <Button sx={{ bgcolor: 'extraWhite', p: 1, textTransform: 'none' }} fullWidth onClick={openAddressInfoPopup} variant='outlined'>
      <Grid container>
        <Title title={title} />
        <Row1 resident_name={resident_name} iconColor={iconColor} />
        <Row2 passenger_name={passenger_name} passenger_number={passenger_number} />
      </Grid>
    </Button >
  </>
}


export default React.memo(InfoSection)
