import React, { useEffect } from 'react'
import { hooks } from '@front/volcanion'


const withContainer = Component => props => {
  const navigate = hooks.useNavigate()
  const pathname = _.get(window, 'location.pathname')
  const mergedProps = {
    pathname,
    navigate
  }

  return (
    <Component {...mergedProps} {...props} />
  )
}

export default withContainer