import React, { useCallback, useEffect } from 'react'
import { hooks } from '@front/volcanion'

import { Loader } from '@front/squirtle'

import HookUtils from '@front/volcanion/utils/hooks'
import GeoUtils from "@front/volcanion/utils/geo"
import TimeUtils from '@front/volcanion/utils/time'

import Callbacks from './callbacks'

const withContainer = Component => ({ order_id, ...props }) => {
  const [eta, setEta] = hooks.useFormState('eta')
  const [order, { isReady }] = hooks.useModel('order', [order_id], {
    single: true,
    populate: ['driver_opts', 'vehicle_opts', 'source.poi', 'destination.poi', 'commercial_package', 'info', 'reservationinfos.reservationinfo', 'commercial_package']
  })
  const [getDetails, etaStatus] = hooks.useModelFunction('address', 'getDetails')
  const getEta = useCallback(Callbacks.getEtaHandler(getDetails, setEta), [getDetails, setEta])

  const load_type = order?.commercial_package?.load_type
  const order_reservationinfo = _.head(order?.reservationinfos)
  useEffect(() => {
    if (!!isReady && load_type === 'user')
      getEta(order)
  }, [load_type, isReady])

  const isLoadingEta = HookUtils.getLoadingState(etaStatus) && load_type === 'user'

  const requestedAtAdapted = TimeUtils.getDetailsMomentFront(order?.requestedAt, order?.source)

  const mergedProps = {
    eta: load_type === 'package' ? _.round(order?.commercial_package?.removal_period_duration) : eta,
    load_type,
    order,
    requestedAtAdapted,
    sourceFormatted: GeoUtils.format(order?.source),
    order_reservationinfo,
    hasReservationInfo: !!order_reservationinfo,
    schedule_type: order?.schedule_type
  }

  return (
    <Loader isLoading={!isReady || !!isLoadingEta}>
      <Component {...mergedProps} {...props} />
    </Loader>
  )
}

export default withContainer