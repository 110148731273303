import { I18n } from '@front/volcanion'

class Callbacks {
  static onRequestSubmitHandler(openNotification, requestSubmit, commercial_package, destination_details, cantBook, comment, ridemode) {
    return function onRequestSubmit() {
      if (!!comment && comment.length > 250)
        return openNotification(I18n.t('comment.too_long'), { variant: 'error' })
      if (!!cantBook)
        return openNotification(I18n.t('order.cantBook'), { variant: 'error' })
      else
        return requestSubmit()
    }
  }
}

export default Callbacks
