import { GeoUtils } from "@front/volcanion/utils"

class Callbacks {

  static onSourceAddressResolveHandler(setAddressDetails, checkReservationInfo, reservationinfo, current_poi, setFormValues, openDialog) {
    return function onSourceAddressResolve(details) {
      const new_poi = _.get(details, 'poi_id')

      if (!details) {
        setFormValues([{ field: 'reservationinfo', value: undefined }])
      }
      if (!!reservationinfo?.reservationinfo_id) {
        if (!!current_poi && current_poi !== new_poi)
          checkReservationInfo(details)
        else
          setAddressDetails(details)
      } else if (!details?.poi && !details?.street_number) {
        openDialog('wrong_source')
      } else {
        setAddressDetails(details)
        checkReservationInfo(details)
      }
    }
  }

  static onAddressResolveHandler(source, destination, prefix, openDialog, setAddressDetails, onSourceAddressResolve) {
    return function onAddressResolve(details, inputRef) {
      inputRef?.current?.blur && inputRef?.current?.blur()
      const isSameAddress = !!source && !!destination && GeoUtils.isSameAddress(source?.details, destination?.details)

      if (!!isSameAddress) {
        openDialog('same_address')
      } else if (prefix === 'source') {
        onSourceAddressResolve(details)
      } else {
        setAddressDetails(details)
      }
    }
  }

  static getOptionIconNameHandler() {
    return function getOptionIconName(option) {
      if (_.get(option, 'type') === 'home') return 'home'
      else if (_.get(option, 'type') === 'work') return 'work'
      else if (_.get(option, 'type') === 'airport') return 'flighttakeoff'
      else if (_.get(option, 'type') === 'festival') return 'festival'
      else if (_.get(option, 'type') === 'train') return 'train'
      else if (_.get(option, 'category') === 'POI') return 'flag'
      else if (_.get(option, 'category') === 'Favorite') return 'star'
      else if (_.get(option, 'category') === 'Recent') return 'update'
      else if (_.get(option, 'favorite')) return 'star'
    }
  }

  static getPoiTypeHandler(prefix, cf, schedule_type) {
    return function getPoiType() {
      const authorized_pois = _.filter(cf?.authorized_pois, poi => _.includes(['all', prefix], poi?.direction_type) && !!poi[`${schedule_type}_order`])
      return _.map(authorized_pois, 'poi_type')
    }
  }

}

export default Callbacks