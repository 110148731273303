import React from 'react'
import { I18n } from '@front/volcanion'

import { Grid, Typography } from '@mui/material'

const PoiAdditionalInfo = ({ meeting_point, ...props }) =>
  <Grid container rowSpacing={2} justifyContent={'flex-end'}>
    <Grid item xs={11}><Typography color={'primary'} variant={'h6'} sx={{ fontSize: 19, }}> {I18n.t('meeting_point.label', { count: 1 })} :</Typography></Grid>
    <Grid item xs={12}><Typography align={'center'}>{meeting_point}</Typography></Grid>
  </Grid>


export default React.memo(PoiAdditionalInfo)
