import React from 'react'
import { I18n } from '@front/volcanion/'

import { Grid } from '@mui/material'

import { MenuSelectorField, DateField } from '@front/squirtle'

const SecondRow = ({ color, ...props }) =>
  <Grid container item xs={12}>
    <Grid item xs>
      <DateField
        name='availableSince'
        label={I18n.t('date.start')}
        inputFormat={'DD/MM/YYYY'}
        mask={'__/__/____'}
        disabled
        test={'toto'}
        disableOpenPicker
      />
    </Grid>
    <Grid item xs>
      <DateField
        name='availableUntil'
        label={I18n.t('date.end')}
        inputFormat={'DD/MM/YYYY'}
        mask={'__/__/____'}
        disabled
        disableOpenPicker
      />
    </Grid>
    <Grid item xs>
      <MenuSelectorField
        name='status'
        label={I18n.t('status.label', { count: 1 })}
        options={[
          { label: I18n.t('status.active'), value: 'active' },
          { label: I18n.t('status.inactive'), value: 'inactive' },
          { label: I18n.t('status.archived'), value: 'archived' },
        ]}
        valueDecoratorProps={{
          sx: {
            WebkitTextFillColor: color,
            fontWeight: 600
          }
        }}
        selectProps={{ IconComponent: null }}
        disabled
      />
    </Grid>
    <Grid item xs />
  </Grid>

export default React.memo(SecondRow)
