import React from 'react'
import { I18n } from '@front/volcanion/'

import { Grid } from '@mui/material'

import { TextField, MenuSelectorField, SingleOptionSelectorField } from '@front/squirtle'

const FirstRow = props =>
  <Grid container item xs={12}>
    <Grid item xs>
      <TextField
        name='client_id'
        label={I18n.t('companycontract.client_id')}
        disabled
      />
    </Grid>
    <Grid item xs>
      <TextField
        name='formula.name'
        label={I18n.t('commercialformula.label', { count: 1 })}
        disabled
      />
    </Grid>
    <Grid item xs>
      <SingleOptionSelectorField
        name={'auth_account'}
        label={I18n.t('payment.deferred')}
        clearOnEmpty={false}
        disabled
      />
    </Grid>
    <Grid item xs>
      <MenuSelectorField
        name='invoice_type'
        options={[
          { label: I18n.t('company.label', { count: 1 }), value: 'company' },
          { label: I18n.t('companycontract.label', { count: 1 }), value: 'contract' },
          { label: I18n.t('companyuser.label', { count: 1 }), value: 'do' },
        ]}
        label={I18n.t('billing.receive')}
        selectProps={{ IconComponent: null }}
        disabled
      />
    </Grid>
  </Grid>

export default React.memo(FirstRow)
