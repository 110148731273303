import React from 'react'
import { I18n } from '@front/volcanion'

import { Box, Fade, Typography, Grid } from '@mui/material'

const Card = ({ label, count }) =>
  <Grid item xs>
    <Box sx={{ justifyContent: 'center', display: 'flex', alignItems: 'center' }}>
      <Typography sx={{ textAlign: 'center', fontWeight: 600, fontSize: 18 }}>{count}</Typography>
      <Typography sx={{ ml: 1, textAlign: 'center', color: 'text.label', fontSize: 12 }}>{label}</Typography>
    </Box>
  </Grid>

const CardHeader = ({ services, contracts, users, ...props }) => <>
  <Box sx={{ display: 'flex', justifyContent: 'center', opacity: 0.9, m: 1, }}>
    <Box sx={{ display: 'flex', boxShadow: 3, borderRadius: 1, mt: -3, bgcolor: 'extraWhite', p: 1 }}>
      <Fade in>
        <Grid container spacing={1}>
          <Card label={I18n.t('companyuser.label', { count: users == 0 ? 'other' : users })} count={users} />
          <Card label={I18n.t('companycontract.label', { count: contracts })} count={contracts} />
          <Card label={I18n.t('companyservice.label', { count: services })} count={services} />
        </Grid>
      </Fade>
    </Box>
  </Box>
</>

export default React.memo(CardHeader)
