import React, { useCallback } from 'react'
import { hooks } from '@front/volcanion'

import Callbacks from './callbacks'

const withContainer = Component => props => {
  const CODE_INTERNATIONAL = hooks.useRelayConstant('CODE_INTERNATIONAL')
  const normalizePhone = useCallback(Callbacks.normalizePhoneHandler(CODE_INTERNATIONAL), [CODE_INTERNATIONAL])

  const mergedProps = {
    normalizePhone
  }

  return (
    <Component {...mergedProps} {...props} />
  )
}

export default withContainer