import React, { useCallback, useEffect } from 'react'
import { I18n, hooks } from '@front/volcanion'

import { Loader } from '@front/squirtle'
import moment from 'moment'

import PopupCallbacks from './Popup/callbacks'
import Utils, { HookUtils, GeoUtils, TimeUtils, FormatUtils } from '@front/volcanion/utils/'
import Callbacks from './callbacks'

const withContainer = Component => props => {
  const FIXED_PRICE = hooks.useRelayFeature('FIXED_PRICE')
  const [user] = hooks.useActiveUser({ populate: ['customerinfo', 'info', 'auth', 'user_companies.companyuser_services.companyservice'] })
  const [, { openDialog }] = hooks.useDialogs()
  const { openNotification } = hooks.useNotification()
  const isReady = hooks.useFormStatus('isReady')
  const [
    duration,
    load_type,
    commercial_package,
    reservationinfo,
    commercial_formula,
    vehicle_opts,
    driver_opts,
    companyservice_id,
    source,
    destination,
    returnAt,
    mode,
    requestedAt
  ] = hooks.useFieldValues(['ridemode_duration', 'load_type', 'commercial_package', 'reservationinfo', 'commercial_formula', 'vehicle_options', 'driver_options', 'service', 'source.details', 'destination.details', 'returnAt', 'ridemode', 'requestedAt'])

  const [{
    schedule_type,
    traffic_alert,
    ridemode_validation,
    unauthorized_schedule,
    displayRoundTrip
  }, setFormState] = hooks.useFormState()

  const [poi_id] = hooks.useFormState('details.source.poi_id')

  const new_ridemode_id = ridemode_validation?.context?.data?.ridemode
  const [new_mode] = hooks.useModel('ridemode', [new_ridemode_id], { single: true })
  const [ridemode] = hooks.useModel('ridemode', [mode], { single: true })

  const [, searchPOI] = hooks.useModelSearch('poi', 'get', { populate: ['type.authorized_pois', 'type.name_trkey'], search_id: 'source_poi' })
  const [validateOrder] = hooks.useModelFunction('order', 'validate')

  const formStateValues = { poi_id, requestedAt, schedule_type }
  const formValues = { commercial_formula, reservationinfo }

  const checkReservationInfo = useCallback(PopupCallbacks.checkReservationInfoHandler(openDialog, searchPOI, formValues, formStateValues), [openDialog, searchPOI, formValues, formStateValues])

  const packages = hooks.useMethodListenerResult('commercialpackage', 'packages_list')
  const packages_status = hooks.useMethodListenerStatus('commercialpackage', 'packages_list')
  const formValidation = useCallback(Callbacks.formValidationHandler(openNotification, commercial_package, packages), [openNotification, commercial_package, packages])
  const handleValidation = useCallback(Callbacks.handleValidationHandler(validateOrder, setFormState), [validateOrder, setFormState])

  const isUserLoadType = load_type === 'user'
  const hasSelectedPackage = !_.isEmpty(commercial_package)
  const hiddeFixedRate = !_.get(user, 'fixed_rate') || !FIXED_PRICE

  hooks.useFormValidation(formValidation, ['commercial_package', 'comment'], [], [HookUtils.getLoadingState([packages_status])])

  const validateWatcher = [
    mode,
    companyservice_id,
    _.join(driver_opts, ','),
    _.join(vehicle_opts, ','),
    requestedAt,
    returnAt,
    Utils.selectFirstKey(source, GeoUtils.getAddressKeys()),
    Utils.selectFirstKey(destination, GeoUtils.getAddressKeys()),
  ]

  const validateArgs = {
    mode,
    companyservice_id,
    source,
    driver_opts,
    vehicle_opts,
    requestedAt,
    returnAt,
    destination,
    duration
  }

  useEffect(() => {
    !!isReady && handleValidation(validateArgs)
  }, validateWatcher)

  useEffect(() => {
    if (!!unauthorized_schedule)
      openDialog('unauthorized_schedule_information', { requestedAt: requestedAt || moment().format(), schedule_type })
    else if (!!traffic_alert)
      openDialog('traffic_alert_information', { record_id: traffic_alert, requestedAt: requestedAt || moment().format() })
    else if (!!ridemode_validation && (!!new_mode || !new_ridemode_id) && ridemode_validation?.context?.reason !== 'missing_args') {
      const context = ridemode_validation?.context
      openDialog('validation_information', {
        title: I18n.t(`validation.ridemode.${context?.criteria}.${context?.reason}.title`),
        description: I18n.t(`validation.ridemode.${context?.criteria}.${context?.reason}.${!!context?.data?.ridemode ? 'redirect' : 'forbidden'}`, {
          min_duration: TimeUtils.secondsToTime(ridemode?.min_duration),
          max_duration: TimeUtils.secondsToTime(ridemode?.max_duration),
          min_night: (ridemode?.min_night || 0) + 1,
          max_night: (ridemode?.max_night || 0) + 1,
          min_distance: FormatUtils.distance(ridemode?.min_distance),
          max_distance: FormatUtils.distance(ridemode?.max_distance),
          ridemode_name: new_mode?.name
        })
      })
    }
  }, [ridemode_validation?.name, ridemode_validation?.reason, new_mode?.name, new_ridemode_id])

  const mergedProps = {
    displayRoundTrip,
    hiddeFixedRate,
    isUserLoadType,
    hasSelectedPackage,
    reservationinfo,
    checkReservationInfo,
  }

  return (
    <Loader isLoading={!isReady}>
      <Component {...mergedProps} {...props} />
    </Loader>
  )
}

export default withContainer
