import React from 'react'
import { I18n, CDNSVG } from '@front/volcanion'

import { List, ListItemButton, ListItemAvatar, ListItemText, Box, Grid, Typography } from '@mui/material'

const Title = props =>
  <Grid item xs={12}>
    <Box sx={{ borderLeft: 4, borderColor: 'secondary.main', mt: 1, display: 'flex' }}>
      <Typography sx={{ fontSize: '2vw', color: 'secondary.main', p: 2 }}>
        {I18n.t('company.label.alter')}
      </Typography>
    </Box>
  </Grid>

const ListItemComponent = ({ label, selected, path, name, onClick }) =>
  <ListItemButton component="a" onClick={onClick} selected={selected}>
    <ListItemAvatar><CDNSVG name={name} fill='primary.main' category={'icons'} /></ListItemAvatar>
    <ListItemText primary={label} primaryTypographyProps={{ sx: !!selected ? { color: 'primary.main', fontWeight: 600 } : {} }} />
  </ListItemButton>


const ListMenu = ({ pathname, navigate }) =>
  <Grid item xs={11}>
    <List>
      <ListItemComponent
        label={I18n.t('menu.dashboard')}
        selected={!!_.includes(pathname, 'dashboard')}
        name={'dashboard'}
        onClick={() => navigate('/admin/dashboard')}
      />
      <ListItemComponent
        label={I18n.t('menu.orders')}
        selected={!!_.includes(pathname, 'order')}
        name={'orders'}
        onClick={() => navigate('/admin/orders')}
      />
      <ListItemComponent
        label={I18n.t('menu.contracts')}
        selected={!!_.includes(pathname, 'contract')}
        name={'contracts'}
        onClick={() => navigate('/admin/contracts')}
      />
      <ListItemComponent
        label={I18n.t('menu.users')}
        selected={!!_.includes(pathname, 'user')}
        name={'users'}
        onClick={() => navigate('/admin/users')}
      />
      {/* <ListItemComponent {...props} label={I18n.t('menu.contacts')} selected={_.includes(pathname,'contact') !== -1} path={'/admin/contacts'}> <Contacts /></ListItemComponent> */}
    </List>
  </Grid>

const CompanyInfo = ({ navigate, pathname }) => <>
  <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
    <Box sx={{ width: '90%', boxShadow: 6, borderRadius: 1, pb: 2 }}>
      <Grid container justifyContent='center'>
        <Title />
        <ListMenu navigate={navigate} pathname={pathname} />
      </Grid>
    </Box>
  </Box>
</>


export default React.memo(CompanyInfo)
