import React from 'react'
import { I18n } from '@front/volcanion'

import { useTheme } from '@mui/styles'

import { Box, Grid, Tooltip, IconButton, Typography } from '@mui/material'
import { SingleOptionSelectorField } from '@front/squirtle'

import { Check, Close } from '@mui/icons-material/'

const TooltipComponent = ({ hasDestination, isValidPaymentType, ...props }) => <>
  <Box sx={{ display: 'flex', alignItems: 'center' }}>
    <IconButton sx={{ color: !hasDestination ? 'red' : 'green' }}>
      {!hasDestination ? <Close /> : <Check />}
    </IconButton>
    <Typography sx={{ color: 'extraWhite', fontSize: 'subtitle.fontSize' }}>   {I18n.t('address.destination_mandatory')} </Typography>
  </Box>
  <Box sx={{ display: 'flex', alignItems: 'center' }}>
    <IconButton color={!isValidPaymentType ? 'error' : 'success'} >
      {!isValidPaymentType ? <Close /> : <Check />}
    </IconButton>
    <Typography sx={{ color: 'extraWhite', fontSize: 'subtitle.fontSize' }}> {I18n.t('payment.forbidden_type.immediate_sender')} </Typography>
  </Box>
</>

const FixedRateSection = ({ onFixedPriceChange, isDisabledFixedPrice, ...props }) => {
  const tooltipContent = <TooltipComponent {...props} />
  const theme = useTheme()
  return (
    <Grid item xs={12} >
      <Tooltip arrow followCursor title={tooltipContent}>
        <Box>
          <Box sx={{ '&:hover': { backgroundColor: 'white' }, bgcolor: 'extraWhite', borderRadius: '4px', p: '3px', pr: 1, pl: 2, border: '1px solid', borderColor: 'rgba(61, 66, 94, 0.5)' }}>
            <SingleOptionSelectorField
              name={'fixed_price'}
              optionLabel={I18n.t('price.fixed.active')}
              afterChange={onFixedPriceChange}
              clearOnEmpty={false}
              disabled={isDisabledFixedPrice}
              getOptionLabelProps={() => ({
                disableTypography: false,
                componentsProps: {
                  typography: { fontSize: 15, color: `${theme.palette.text.label} !important` },
                },
                labelPlacement: 'end'
              })}
              formGroupProps={{ sx: { mt: 0, justifyContent: 'flex-start' } }}
            />
          </Box>
        </Box>
      </Tooltip>
    </Grid>
  )
}

export default React.memo(FixedRateSection)
