
class Callbacks {
  static filterToFormHandler() {
    return function filterToForm(filter) {
      return {
        ...filter
      }
    }
  }
  static filterToStateHandler(contract) {
    return function filterToState(state) {
      return {
      }
    }
  }
  static formToFilterHandler(initialRoleFilter) {
    return function formToFilter(values, extra, meta, state) {
      return {
        or: [
          {
            status: {
              '!': ['complete', 'canceled'],
            },
            schedule_type: 'immediate',
            ...initialRoleFilter,
            ...values
          },
          {
            status: ['attributed'],
            schedule_type: 'planned',
            ...initialRoleFilter,
            ...values
          }
        ],

      }
    }
  }
}

export default Callbacks
