import React from 'react'
import { hooks } from '@front/volcanion'
import { useTheme } from '@mui/styles'

import StatusUtils from '@onix/utils/status'

const withContainer = Component => props => {
  const [status] = hooks.useFieldValues(['status'])
  const theme = useTheme()
  const mergedProps = {
    color: !!status ? StatusUtils.getColor(status) : theme.palette.primary.main
  }

  return (
    <Component {...mergedProps} />
  )
}

export default withContainer
