import React, { useCallback } from 'react'
import { hooks, ModelForm } from '@front/volcanion'

import Callbacks from './callbacks'
import PopupCallbacks from './Popup/callbacks'
import { HookUtils } from '@front/volcanion/utils'
import { Loader } from '@front/squirtle'

const withForm = Component => ({ form_name = 'order_default_form', user }) => {
  const [, { openDialog }] = hooks.useDialogs()
  const { openNotification } = hooks.useNotification()
  const openResumeOrderPopup = useCallback(PopupCallbacks.openResumeOrderPopupHandler(openDialog), [openDialog])

  const [source_details] = hooks.useGlobalFormState('order_default_form', 'details.source')
  const packages = hooks.useMethodListenerResult('commercialpackage', 'packages_list')
  const [customer_info, customer_info_state] = hooks.useCustomerInfo(user?.user_id)

  const getEstimation = hooks.useEstimationSelector()

  const formProps = {
    name: form_name,
    model_name: 'order',
    getEmptyForm: useCallback(Callbacks.getEmptyFormHandler(user, customer_info), [user, customer_info]),
    getEmptyState: useCallback(Callbacks.getEmptyStateHandler(user), [user]),
    formToRecord: useCallback(Callbacks.formToRecordHandler(getEstimation), [getEstimation]),
    formToOptions: useCallback(Callbacks.formToOptionsHandler(getEstimation), [getEstimation]),
    populate: [
    ],
    initialState: {
      schedule_type: 'immediate'
    },
    onSubmitSuccess: useCallback(Callbacks.onSubmitSuccessHandler(openResumeOrderPopup), [openResumeOrderPopup]),
    onSubmitFailed: useCallback(Callbacks.onSubmitFailedHandler(openDialog, packages), [openDialog, packages]),
    getSuccessNotification: useCallback(Callbacks.getSuccessNotificationdHandler(), []),
    getFailedNotification: useCallback(Callbacks.getFailedNotificationHandler(), []),
    redirectOnGenerate: false,
    includeReadOnly: true,
    generateChangesOnly: false,
    submitChangesOnly: false,
    disableDefaultNotification: true,
    beforeSubmit: useCallback(Callbacks.beforeSubmitHandler(openDialog, openNotification, source_details), [openDialog, openNotification, source_details])
  }
  const mergedProps = {

  }

  return (
    <Loader isLoading={HookUtils.getLoadingState([customer_info_state])}>
      <ModelForm {...formProps}>
        <Component {...mergedProps} />
      </ModelForm>
    </Loader>
  )
}

export default withForm
