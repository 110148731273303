import React, { useCallback } from 'react'

import { ModelForm } from '@front/volcanion'
import { hooks } from '@front/volcanion'

import Callbacks from './callbacks'

const withForm = Component => props => {
  const { companycontract_id, ...rest } = hooks.useParams()

  const formProps = {
    record_id: companycontract_id,
    name: `companycontract_form`,
    model_name: 'companycontract',
    populate: ['contact', 'formula', 'comment', 'source_address'],
    initialState: { isReadOnly: false },
    getEmptyForm: useCallback(Callbacks.getEmptyFormHandler(), []),
    recordToForm: useCallback(Callbacks.recordToFormHandler(), []),
    recordToState: useCallback(Callbacks.recordToStateHandler(), []),
    formToRecord: useCallback(Callbacks.formToRecordHandler(), []),
    onSubmitSuccess: useCallback(Callbacks.onSubmitSuccessHandler(), []),
    onSubmitFailed: useCallback(Callbacks.onSubmitFailedHandler(), [])
  }

  const mergedProps = {
  }

  return (
    <ModelForm {...formProps}>
      <Component {...mergedProps} />
    </ModelForm>
  )
}

export default withForm
