import React, { useCallback } from 'react'

import { hooks, ModelForm } from '@front/volcanion'

import Callbacks from './callbacks'

const withForm = Component => props => {
  const { companycontact_id, ...rest } = hooks.useParams()
  const [selectedCompany] = hooks.useGlobalState('selectedCompany')
  const formProps = {
    record_id: companycontact_id,
    name: `companyuser_form`,
    model_name: 'companycontact',
    populate: ['contact'],
    initialState: { isReadOnly: !!companycontact_id },
    getEmptyForm: useCallback(Callbacks.getEmptyFormHandler(), []),
    recordToForm: useCallback(Callbacks.recordToFormHandler(), []),
    recordToState: useCallback(Callbacks.recordToStateHandler(), []),
    formToRecord: useCallback(Callbacks.formToRecordHandler(selectedCompany), [selectedCompany]),
    onSubmitSuccess: useCallback(Callbacks.onSubmitSuccessHandler(), []),
    onSubmitFailed: useCallback(Callbacks.onSubmitFailedHandler(), [])
  }

  const mergedProps = {
  }

  return (
    <ModelForm {...formProps}>
      <Component {...mergedProps} />
    </ModelForm>
  )
}

export default withForm
