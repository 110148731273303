import React, { useEffect } from 'react'
import { hooks } from '@front/volcanion'


const withContainer = Component => props => {
  const [selectedCompany, setSelectedCompany] = hooks.useGlobalState('selectedCompany')
  const [companyservices, searchCompanyservices] = hooks.useModelSearch('companyservice', 'get')

  const [company] = hooks.useModel('company', [selectedCompany], { single: true, populate: ['companyusers', 'contracts'] })

  const services = (companyservices || []).length
  const contracts = (_.get(company, 'contracts') || []).length
  const users = (_.get(company, 'companyusers') || []).length

  useEffect(() => {
    !!selectedCompany && searchCompanyservices({ contract: { company: selectedCompany } })
  }, [selectedCompany])

  const mergedProps = {
    setSelectedCompany,
    selectedCompany,
    services,
    contracts,
    users
  }

  return (
    <Component {...mergedProps} {...props} />
  )
}

export default withContainer