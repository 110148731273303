import React from 'react'
import { hooks } from '@front/volcanion'

const withContainer = Component => ({ driver_missing_options, vehicle_missing_options, ...props }) => {

  const [driver_options] = hooks.useModel('useroption', driver_missing_options)
  const [vehicle_options] = hooks.useModel('vehicleoption', vehicle_missing_options)

  const driver_ots_name = _.join(_.map(driver_options, 'name_translated'), ', ')
  const vehicle_opts_name = _.join(_.map(vehicle_options, 'name_translated'), ', ')

  const mergedProps = {
    driver_ots_name,
    vehicle_opts_name
  }

  return (
    <Component {...mergedProps} {...props} />
  )
}

export default withContainer