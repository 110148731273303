import React from 'react'
import { hooks } from '@front/volcanion'

import StatusUtils from '@onix/utils/status'

const withContainer = Component => props => {
  const requestSubmit = hooks.useFormFunction('requestSubmit')
  const [status] = hooks.useFieldValues(['status.name'])

  const mergedProps = {
    requestSubmit,
    color: StatusUtils.getColor(status)
  }


  return (
    <Component {...mergedProps} {...props} />
  )
}

export default withContainer
