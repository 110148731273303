class Callbacks {
  static filterToFormHandler() {
    return function filterToForm(filter) {
      return {
        status: {
          name: 'active'
        },
        ...filter
      }
    }
  }
  static filterToStateHandler(contract) {
    return function filterToState(state) {
      return {
        contract
      }
    }
  }
  static formToFilterHandler() {
    return function formToFilter(values, extra, meta, state) {

      return {
        name: _.get(values, 'name') ? _.get(values, 'name') : undefined,
        client_id: _.get(values, 'client_id') ? _.get(values, 'client_id') : undefined,
        contract: _.get(state, 'contract'),
        contact: {
          email: _.get(values, 'contact.email') ? _.get(values, 'contact.email') : undefined
        },
        status: !!_.get(values, 'status.name') ? [_.get(values, 'status')] : undefined,
      }
    }
  }
}

export default Callbacks
