import React from 'react'
import { hooks } from '@front/volcanion'

const withContainer = Component => props => {
  const [comment] = hooks.useFieldValues(['comment'])
  const BOOKING_COMMENT_ENABLED = hooks.useRelayFeature('BOOKING_COMMENT_ENABLED')

  const count = (comment || '').length
  const hidden = !BOOKING_COMMENT_ENABLED

  const mergedProps = {
    hidden,
    count
  }

  return <Component {...mergedProps} {...props} />
}

export default withContainer