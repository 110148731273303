import React from 'react'
import { I18n, CDNSVG } from '@front/volcanion'

import { Grid, Button, Box, Icon, Typography } from '@mui/material'
import { FormDialog, DialogContent, TextField } from '@front/squirtle'

import PassengerPopup from '../../Popup/Passenger'

const typographyProps = {
  ml: '2px', mr: '2px', fontWeight: 100, fontSize: 13, WebkitTextFillColor: 'text.label'
}

const Passenger = ({ passenger_name, passenger_number, load_count, luggage, ...props }) =>
  <Box>
    <Box sx={{ display: 'flex', justifyContent: 'center', overFlow: 'auto' }}>
      <TextField name='passenger_name' InputProps={{ inputProps: { style: { ...typographyProps, textAlign: 'right' } } }} disabled />
      <Typography sx={{ ...typographyProps, fontWeight: 400, fontSize: 13 }}>-</Typography>
      <TextField name='passenger_number' InputProps={{ inputProps: { style: { ...typographyProps } } }} disabled />
    </Box>
    <Box sx={{ display: 'flex', justifyContent: 'center', overflow: 'auto' }}>
      <Typography sx={typographyProps}> {load_count} {I18n.t('passenger.label', { count: 1 })}</Typography>
      <Typography sx={{ ...typographyProps, fontWeight: 400, fontSize: 13 }}>-</Typography>
      <Typography sx={typographyProps}>
        {I18n.t(`luggage.${!!luggage ? 'with' : 'without'}`)}
      </Typography>
    </Box>
  </Box >

const DisabledPassenger = props => <>
  <Box sx={{ display: 'flex', justifyContent: 'center', overFlow: 'auto' }}>
    <Typography sx={typographyProps}> {I18n.t('driver.notify')}</Typography>
  </Box>
</>

const PassengerSection = ({ passengerPopupProps, load_type, disabledPassenger, displayGuestField, openPassengerPopup, submitPassengerPopup, ...props }) =>
  <Grid item xs={12} hidden={load_type === 'package'}>
    <FormDialog name={'passenger_form'} formProps={{ onSubmit: submitPassengerPopup, ...passengerPopupProps }}>
      <DialogContent> <PassengerPopup /> </DialogContent>
    </FormDialog>

    <Button sx={{ bgcolor: 'extraWhite', p: 1, textTransform: 'none' }} fullWidth onClick={openPassengerPopup} variant='outlined' disabled={disabledPassenger}>
      <Box sx={{ display: 'flex', alignItems: 'center', width: 1 }} >
        <Box sx={{ color: 'primary.main', width: '4%', fontSize: 27 }}><Icon>{'group'}</Icon></Box>
        <Box sx={{ width: '92%' }}>{!disabledPassenger ? <Passenger {...props} /> : <DisabledPassenger />}</Box>
        <Box>
          <CDNSVG name='edit' fill='primary.main' category={'icons'}/>
        </Box>
      </Box>
    </Button>
  </Grid>

export default React.memo(PassengerSection)
