import React from 'react'

import { Box, Grid } from '@mui/material'

import SelectedGrid from './Selected'

const PackageSection = ({ openPackageFormPopup, isEstimationLoading }) =>
  <Grid container item xs={6} justifyContent='center' rowSpacing={2}>
    <Grid item xs={12}>
      <Box sx={{ display: 'flex', alignItems: 'center', p: 1 }} >
        <Grid container spacing={1} rowSpacing={1.5} justifyContent={'center'} >
          <Grid item xs={11}>
            <SelectedGrid
              openPackageFormPopup={openPackageFormPopup}
              isLoading={isEstimationLoading}
            />
          </Grid>
        </Grid>
      </Box>
    </Grid>
  </Grid>

export default React.memo(PackageSection)
