import React from 'react'
import { I18n } from '@front/volcanion'

import { alpha, styled } from '@mui/material/styles'

import { Box, Grid, Switch, Typography } from '@mui/material'

const CustomColorSwitch = styled(Switch)(({ theme }) => ({
  '& .MuiSwitch-switchBase.Mui-checked': {
    color: theme.palette.secondary.main,
    '&:hover': {
      backgroundColor: alpha(theme.palette.secondary.main, theme.palette.action.hoverOpacity),
    },
  },
  '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
    backgroundColor: theme.palette.secondary.main,
  },
}))

const LoadSection = ({ PACKAGE_ENABLED, load_package, onLoadTypeChange, ...props }) => {
  const unSelectedStyle = { color: 'grayDark', fontWeight: 100, fontSize: 22, letterSpacing: 1.5, opacity: 0.8 }
  const selectedStyle = { color: 'secondary.main', fontWeight: 100, fontSize: 22, letterSpacing: 1.5 }
  return (
    <Grid item xs={12} hidden={!PACKAGE_ENABLED}>
      <Box sx={{ display: 'flex' }}>
        <Typography sx={!load_package ? selectedStyle : unSelectedStyle}> {I18n.t('passenger.label', { count: 1 })} </Typography>
        <Box>
          <CustomColorSwitch size='medium' value={load_package} onChange={onLoadTypeChange} />
        </Box>
        <Typography sx={!!load_package ? selectedStyle : unSelectedStyle} > {I18n.t('package.label', { count: 2 })} </Typography>
      </Box>
    </Grid >
  )
}

export default React.memo(LoadSection)
