import GeoUtils from '@front/volcanion/utils/geo'
class Callbacks {
  static recordToFormHandler() {
    return function recordToForm(record) {
      const status = !!_.get(record, 'deleted')
        ? 'archived'
        : !!_.get(record, 'active')
          ? 'active'
          : 'inactive'

      return {
        client_id: _.get(record, 'client_id'),
        formula: {
          name: _.get(record, 'formula.name')
        },
        auth_account: !!_.get(record, 'auth_account'),
        invoice_type: _.get(record, 'invoice_type'),
        availableSince: _.get(record, 'availableSince'),
        availableUntil: _.get(record, 'availableUntil'),
        status,
        contact: {
          address: _.get(record, 'contact.address'),
          email: _.get(record, 'contact.email')
        },
        comment: _.get(record, 'comment.text'),
        address: {
          formatted_address: GeoUtils.mergeAddress(record?.source_address)
        },
        source_address: record?.source_address
      }
    }
  }

  static getEmptyFormHandler() {
    return function getEmptyFormValues() {
      return {
      }
    }
  }

  static formToRecordHandler() {
    return function formToRecord(values, extra, meta) {
      const status = !!_.get(values, 'deleted')
        ? 'archived'
        : !!_.get(values, 'active')
          ? 'active'
          : 'inactive'

      return {
        client_id: _.get(values, 'client_id'),
        formula: {
          name: _.get(values, 'formula.name')
        },
        deferred: _.get(values, 'deferred'),
        invoice_type: _.get(values, 'invoice_type'),
        availableSince: _.get(values, 'availableSince'),
        availableUntil: _.get(values, 'availableUntil'),
        status,
        contact: {
          address: _.get(values, 'contact.address'),
          email: _.get(values, 'contact.email')
        },
        comment: _.get(values, 'comment'),
        source_address: values?.source_address
      }
    }
  }

  static recordToStateHandler() {
    return function recordToState(record) {
      return {
        client_id: _.get(record, 'client_id')
      }
    }
  }

  static onSubmitSuccessHandler() {
    return async function onSubmitSuccess(result, values, extra, meta) {
      console.log("🚀 ~ file: callbacks.js ~ line 27 ~ Callbacks ~ onSubmitSuccess ~ result, values, extra, meta", result, values, extra, meta)

    }
  }
  static onSubmitFailedHandler() {
    return async function onSubmitFailed(err, values, extra, meta) {
      console.log("🚀 ~ file: callbacks.js ~ line 27 ~ Callbacks ~ onSubmitFailed ~ err, values, extra, meta", err, values, extra, meta)

    }
  }
}

export default Callbacks
