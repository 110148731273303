import React from 'react'
import { I18n } from '@front/volcanion'

import { FormDialog, DialogContent, ConfirmationDialog, InformationDialog } from '@front/squirtle'

import {
  PoiAdditionalInfo,
  PoiAdditionalInfoForm,
  ResumeBooking,
  TrafficAlertDialog,
  UnauthorizedScheduleDialog,
  IncompatibleLoadCountDialog,
  InvalidDateTimeDialog
} from './'

import PackageForm from '@onix/elements/popup/Package'

const DialogManager = ({
  requestedAt,
  vehicle_count,
  service,
  commercial_package,
  submitPoiAdditionalInfoForm,
  onCloseResumeOrder,
  onCloseScheduleInformation,
  onCloseTrafficAlertInformation,
  onCancelScheduleChange,
  onConfirmScheduleChange,
  onCancelAddressChange,
  onConfirmAddressChange,
  onSameAddress,
  onSubmitPackageFormPopup,
  onCancelIncompatible,
  onConfirmIncompatible,
  onCloseInformationValidation
}) => (
  <>
    <InformationDialog
      name={'wrong_source'}
      title={I18n.t('error.order.label')}
      description={I18n.t('error.order.coverage.source')}
    />
    <InformationDialog
      name={'same_address'}
      title={I18n.t('address.popup.impossible_booking_same_address.title')}
      description={I18n.t('address.popup.impossible_booking_same_address.description')}
      onClose={onSameAddress}
    />
    <ConfirmationDialog
      name={'confirmation_reservation_invalid_schedule'}
      onCancel={onCancelScheduleChange}
      onConfirm={onConfirmScheduleChange}
    />

    <InformationDialog
      name={'information_invalid_datetime'}
    >
      <DialogContent><InvalidDateTimeDialog /></DialogContent>
    </InformationDialog>

    <ConfirmationDialog
      name={'confirmation_reservation_invalid_address'}
      onCancel={onCancelAddressChange}
      onConfirm={onConfirmAddressChange}
    />
    <InformationDialog name={'resume_order'} onClose={onCloseResumeOrder}>
      <DialogContent><ResumeBooking /></DialogContent>
    </InformationDialog>
    <InformationDialog name={'information_popup'} />
    <InformationDialog name={'information_popup_poi'} >
      <DialogContent> <PoiAdditionalInfo /> </DialogContent>
    </InformationDialog>
    <FormDialog
      name={'form_popup_poi'}
      allowCancel={false}
      formProps={{
        onSubmit: submitPoiAdditionalInfoForm
      }}
    >
      <DialogContent> <PoiAdditionalInfoForm /> </DialogContent>
    </FormDialog>

    <InformationDialog name={'traffic_alert_information'} maxWidth={'md'} onClose={onCloseTrafficAlertInformation}>
      <DialogContent>
        <TrafficAlertDialog />
      </DialogContent>
    </InformationDialog>

    <InformationDialog name={'unauthorized_schedule_information'} onClose={onCloseScheduleInformation}>
      <DialogContent>
        <UnauthorizedScheduleDialog />
      </DialogContent>
    </InformationDialog>


    <ConfirmationDialog name={'incompatible_load_count_confirmation'} onConfirm={onConfirmIncompatible} onCancel={onCancelIncompatible}>
      <DialogContent>
        <IncompatibleLoadCountDialog title={I18n.t('warning.label', { count: 1 })} />
      </DialogContent>
    </ConfirmationDialog>
    <InformationDialog name={'incompatible_load_count_information'} onClose={onConfirmIncompatible}>
      <DialogContent>
        <IncompatibleLoadCountDialog title={I18n.t('information.label', { count: 1 })} />
      </DialogContent>
    </InformationDialog>
    <FormDialog
      name={'package_form'}
      formProps={{
        onSubmit: onSubmitPackageFormPopup,
        initialValues: { commercial_package, vehicle_count },
        initialState: { requestedAt, service }
      }}
    >
      <DialogContent>
        <PackageForm />
      </DialogContent>
    </FormDialog>

    <InformationDialog
      name={'validation_information'}
      maxWidth={'md'}
      onClose={onCloseInformationValidation}
      _header={{ textAlign: 'center', fontSize: 20, color: 'secondary.600' }}
      _description={{ sx: { textAlign: 'center', fontSize: 16, color: 'primary.600' } }}
    />

  </>
)

export default React.memo(DialogManager)
