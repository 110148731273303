import React from 'react'
import { I18n } from '@front/volcanion/'

import { Grid } from '@mui/material'

import { TextField } from '@front/squirtle'

const FifthhRow = props =>
  <Grid container item xs={12}>
    <Grid item xs>
      <TextField
        name='comment'
        label={I18n.t('comment.admin')}
        disabled
        rows={2}
        variant={'outlined'}
      />
    </Grid>
  </Grid>


export default React.memo(FifthhRow)
