import React from 'react'
import { I18n } from '@front/volcanion'

import { Box, Button, Grid, Typography } from '@mui/material'

import { TextField, MenuSelectorField } from '@front/squirtle'

const SearchForm = ({ color, requestSubmit, handleCreate, ...props }) => <>
  <Box sx={{ p: 2, boxShadow: 6 }}>
    <Grid container spacing={3} alignItems='flex-end'>
      <Grid item xs>
        <TextField
          name='name'
          label={I18n.t('name.label', { count: 1 })}
        />
      </Grid>
      <Grid item xs>
        <TextField
          name='client_id'
          label={I18n.t('companyservice.code')}
        />
      </Grid>
      <Grid item xs>
        <TextField
          name='contact.email'
          label={I18n.t('email.label', { count: 1 })}
        />
      </Grid>
      <Grid item xs>
        <MenuSelectorField
          name={'status.name'}
          options={[
            { label: I18n.t('status.active'), value: 'active' },
            { label: I18n.t('status.inactive'), value: 'inactive' },
            { label: I18n.t('status.archived'), value: 'archived' }
          ]}
          disableUnderline
          noneLabel={I18n.t('all')}
          valueDecoratorProps={{
            sx: {
              WebkitTextFillColor: color,
              fontWeight: 600
            }
          }}
        />
      </Grid>
      <Grid item xs>
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <Button onClick={requestSubmit} variant={'outlined'} size={'small'} sx={{ textTransform: 'none' }}>
            <Typography sx={{ fontSize: 15 }}>
              {I18n.t('action.search')}
            </Typography>
          </Button>
        </Box>
      </Grid>
    </Grid>
  </Box>
</>

export default React.memo(SearchForm)
