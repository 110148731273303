import React from 'react'
import { I18n } from '@front/volcanion'

import { Box, Typography, Icon, Divider } from '@mui/material'

const TrafficAlert = ({ margin, reason, margin_time, requestedAt }) =>
  <Box>
    <Box sx={{ display: 'flex', alignItems: 'flex-end', justifyContent: 'center' }}>
      <Box sx={{ fontSize: 33 }}><Icon fontSize='inherit' color='secondary'>{'warning'}</Icon></Box>
      <Typography variant={'h5'} ml={2}> {I18n.t('traffic_alert.popup.title')} </Typography>
    </Box>

    <Divider sx={{ mt: 2, mb: 2 }} />

    <Typography variant={'h6'}>{I18n.t('traffic_alert.popup.description')}</Typography>

    <Box sx={{ display: 'flex', mt: 2 }}>
      <Typography variant={'h6'}> {`${I18n.t('reason.label', { count: 1 })}:`}</Typography>
      <Typography variant={'h6'} ml={1} fontWeight={'bold'}> {reason}</Typography>
    </Box>

    <Box sx={{ display: 'flex' }}>
      <Typography variant={'h6'}> {`${I18n.t('margin.label', { count: 1 })}:`}</Typography>
      <Typography variant={'h6'} ml={1} fontWeight={'bold'}> {margin}</Typography>
    </Box >

    <Box sx={{ display: 'flex' }}>
      <Typography variant={'h6'}> {I18n.t('traffic_alert.popup.period')}</Typography>
      <Typography variant={'h6'} ml={1} fontWeight={'bold'}> {`${requestedAt} ${I18n.t('prefix.and')} ${margin_time}`}</Typography>
    </Box >

  </Box >

export default TrafficAlert
