import React from 'react'

import { Box } from '@mui/material'

import QuickOrderFrame from '@onix/frames/QuickOrder'
import Orders from './Section/Orders'

const Dashboard = ({ onQuickOrderChange, quickOrder, ...props }) =>
  <Box height={1} bgcolor='extraWhite'>
    <Box sx={{ boxShadow: 6, bgcolor: 'extraWhite', p: 1 }}>
      <QuickOrderFrame onQuickOrderChange={onQuickOrderChange} />
    </Box>
    <Orders />
  </Box>

export default React.memo(Dashboard)
