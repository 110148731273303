
import { I18n } from '@front/volcanion'

import moment from 'moment'
import _ from 'lodash'

import { GeoUtils, TimeUtils } from '@front/volcanion/utils/'
import FormatUtils from '@front/squirtle/utils/format'

class Callbacks {
  static getEmptyFormHandler(user, customer_info) {
    return function getEmptyForm() {
      const passenger_number = customer_info?.number
      const passenger_name = customer_info?.name
      return {
        requestedAt: null,
        estimation_type: 'estimated',
        commercial_formula: _.get(user, 'commercial_formula'),
        comment: user?.customerinfo?.comment_to_driver,
        vehicle_count: 1,
        load_type: 'user',
        passenger_name,
        passenger_number,
        load_count: 1,
        luggage: false,
        source: {
          info: {
            passenger_name,
            passenger_number
          }
        },
        reservationinfo: {
          type: 'meeting_point'
        }
      }
    }
  }
  static getEmptyStateHandler(user) {
    return function getEmptyState(record) {
      return {
        displayRoundTrip: false,
        saved_package: null,
        user_id: _.get(user, 'user_id'),
        schedule_type: 'immediate',
      }
    }
  }
  static formToOptionsHandler(getEstimation) {
    return function formToOptions(values, extra, meta, state) {
      const estimation = getEstimation(values?.commercial_package)
      return ({
        repeat: _.get(values, 'vehicle_count'),
        single: (_.get(values, 'vehicle_count') || 1) === 1,
        override_multiple_order_check: true,
        load_type: _.get(values, 'load_type'),
        load_count: _.get(values, 'load_count'),
        manual_price: _.get(values, 'manual_price'),
        estimation_type: estimation?.type,
        application: _.get(values, 'user.application')
      })
    }
  }

  static formToRecordHandler(getEstimation) {
    return function formToRecord(values, extra, meta, state) {
      const estimation = getEstimation(values?.commercial_package)

      const formatted_requestedAt = state?.schedule_type === 'immediate'
        ? undefined
        : TimeUtils.getDetailsMomentBack(FormatUtils.formatDateFrontToBack(values?.requestedAt), values?.source?.details)

      return {
        do: !_.get(state, 'guest') ? state?.user_id : undefined,
        source: {
          ..._.pick(_.get(values, 'source.details'), _.flatten([GeoUtils.getAddressKeys(), ['name', 'owner']])),
          ..._.pick(_.get(values, 'source.info'), ['resident_name'])
        },
        destination: !!_.has(values, 'destination') ? _.merge(
          {}, {
          ..._.pick(_.get(values, 'destination.details'), _.flatten([GeoUtils.getAddressKeys(), ['name', 'owner']])),
          ..._.pick(_.get(values, 'destination.info'), ['resident_name']),
        }) : undefined,
        loads: {
          src_contact: _.get(values, 'source.info.passenger_name'),
          src_phone: !!_.has(values, 'source.info.passenger_number') ? FormatUtils.parsePhoneNumber(_.get(values, 'passenger_number')) : undefined,
          dest_contact: _.get(values, 'destination.info.passenger_name'),
          dest_phone: !!_.has(values, 'destination.info.passenger_number') ? FormatUtils.parsePhoneNumber(_.get(values, 'passenger_number')) : undefined
        },
        info: {
          meeting_point_trkey: values?.reservationinfo?.arrival_trkey,
          driver_instruction_trkey: values?.reservationinfo?.driver_instruction_trkey,
          duration: values?.ridemode_duration
        },
        mode: values?.ridemode,
        has_luggage: _.get(values, 'luggage'),
        calling_number: _.get(values, 'passenger_number'),
        requestedAt: formatted_requestedAt,
        returnAt: values?.returnAt ? TimeUtils.getDetailsMomentBack(FormatUtils.formatDateFrontToBack(values?.returnAt), values?.source?.details) : undefined,
        payment_type: _.get(values, 'payment_type'),
        customer_ref: _.get(values, 'customer_ref'),
        commercial_package: _.get(values, 'commercial_package'),
        estimation_id: estimation?.estimation_id,
        driver_opts: _.get(values, 'driver_options'),
        vehicle_opts: _.get(values, 'vehicle_options'),
        schedule_type: _.get(state, 'schedule_type'),
        driver_comment: _.has(values, 'comment') ? _.get(values, 'comment') : undefined,
        confirmed: true,
        payment_method: null,
        service: values?.service,
        reservationinfos: _.has(values, 'reservationinfo.reservationinfo_id') ? [{
          reservationinfo: values?.reservationinfo?.reservationinfo_id,
          name: values?.reservationinfo?.name,
          origin: values?.reservationinfo?.origin
        }] : undefined
      }
    }
  }

  static onSubmitSuccessHandler(openResumeOrderPopup) {
    return function onSubmitSuccess(result, values, extra, meta, state) {
      openResumeOrderPopup(_.get(values, 'vehicle_count') > 1 ? _.get(_.head(result), 'order_id') : _.get(result, 'order_id'))
      console.log("🚀 ~ file: callbacks.js ~ line 166 ~ Callbacks ~ onSubmitSuccess ~ result", result)
    }
  }
  static onSubmitFailedHandler(openDialog, packages) {
    return function onSubmitFailed(err, values, extra, meta, state) {
      console.log("🚀 ~ file: callbacks.js ~ line 171 ~ Callbacks ~ onSubmitFailed ~ err", err, meta)

      if (err?.name === 'MismatchError' && err.getCode() === 'UNAVAILABLE_COMMERCIAL_PACKAGE') {
        const current_cp = _.find(packages, ['commercial_package_id', values?.commercial_package])
        openDialog('information_invalid_datetime', {
          title: current_cp?.reason === 'immediate' ? I18n.t('dialog.information_immediate_forbidden.title') : I18n.t('dialog.information_invalid_datetime.title'),
          _header: { _text: { fontSize: 20, color: 'primary' } },
          requestedAt: state?.requestedAt,
          commercial_package_id: values?.commercial_package,
          reason: current_cp?.reason
        }, { requestedAt: state?.requestedAt, commercial_package_id: values?.commercial_package })
      }
    }
  }

  static beforeSubmitHandler(openDialog, openNotification, source_details) {
    return function beforeSubmit(values, extra, meta, state) {
      if (!!state?.unauthorized_schedule) {
        openDialog('unauthorized_schedule_information', { requestedAt: values?.requestedAt, schedule_type: values?.schedule_type })
        return false
      }
      else if (!source_details?.poi && !source_details?.street_number) {
        openDialog('wrong_source')
        return false
      }
      else if (values?.load_type === 'package' && !values?.source?.info?.resident_name)
        openNotification(I18n.t('error.order.company.source'), { variant: 'error' })
      else if (values?.load_type === 'package' && !values?.destination?.info?.resident_name)
        openNotification(I18n.t('error.order.company.destination'), { variant: 'error' })
      else
        return true
    }
  }

  static getFailedNotificationHandler() {
    return function getFailedNotification(err, values, extra, meta, state) {
      const code = err.getCode()
      const context = err.getContext()

      if (code === 'UNAUTHORIZED_SERVICE_SCHEDULE') {
        return [
          I18n.t('unauthorized_schedule.popup.description', {
            order_type: I18n.t(`order.${state?.schedule_type}.longLabel`, { count: 1 }),
            requestedAt: moment(state?.requestedAt).format(state?.schedule_type === 'immediate' ? 'HH[h]mm[min]' : `[${_.toLower(I18n.t('prefix.the'))}] DD/MM/YYYY HH[h]mm[min]`)
          })
          , { variant: 'error' }
        ]
      }
      else if (code === 'MISSING_ARGUMENTS' && _.includes(_.get(context, 'keys'), 'commercial_package'))
        return [I18n.t('error.package.empty'), { variant: 'error' }]
      else if (code === 'PAYMENT_TYPE_MISMATCH')
        return [I18n.t('error.payment_type.invalid'), { variant: 'error' }]
      else return []
    }
  }
  static getSuccessNotificationdHandler() {
    return function getSuccessNotificationd() {
      return [I18n.t('notification.order.success'), { variant: 'success' }]
    }
  }

  static formValidationHandler(openNotification, selectedCommercialPackage, packages) {
    return function formValidation(values) {
      const { comment } = values
      const current_cp = _.find(packages, (cp) => cp.commercial_package_id === selectedCommercialPackage)
      const reason = _.get(current_cp, 'reason')
      const context = _.get(current_cp, 'context')
      const errors = []

      if (!_.get(current_cp, 'available')) {
        if (reason === 'destination')
          errors.push({ field: 'destination.details', message: I18n.t('validation.destination_mandatory') })
        else if (reason === 'immediate')
          errors.push({ field: 'requestedAt', message: I18n.t('validation.immediate_order'), meta: { error: true } })
        else if (reason === 'planned')
          errors.push({ field: 'requestedAt', message: I18n.t('validation.planned_order') })
        else if (reason === 'min_time')
          errors.push({
            field: 'requestedAt', message: I18n.t('validation.min_time_order', { min_time: TimeUtils.secondsToTime(_.get(context, 'min_time') * 60, `DD/MM/YY [${I18n.t('prefix.at')}] HH[h]:mm`) })
          })
        else if (reason === 'max_time') {
          errors.push({
            field: 'requestedAt', message: I18n.t('validation.max_time_order', { max_time: TimeUtils.secondsToTime(_.get(context, 'max_time') * 60, `DD/MM/YY [${I18n.t('prefix.at')}] HH[h]:mm`) })
          })
        }
        else if (reason === 'max_load')
          openNotification(I18n.t('validation.max_load', { max_load: _.get(context, 'max_load') }), { variant: 'error' })
      }
      const requestedAt = TimeUtils.getDetailsMomentBack(values?.requestedAt, values?.source?.details)
      const frontrequestedAt = TimeUtils.getDetailsMomentFront(requestedAt, values?.source?.details)

      if (!!values?.requestedAt && moment.utc(requestedAt).isBefore(moment.utc().subtract(15, 'minute'), 'minute')) {
        errors.push({ field: 'requestedAt', message: I18n.t('validation.past_date') })
      }
      if (!!comment && comment.length > 250)
        errors.push({ field: 'comment', message: I18n.t('comment.too_long') })

      return errors
    }
  }
  static handleValidationHandler(validateOrder, setFormState) {
    return async function handleValidation(params) {
      try {
        if (!params?.requestedAt) return null
        const validations = await validateOrder(params)
        const traffic_alert = _.find(validations, ['name', 'traffic'])
        const unauthorized = _.find(validations, ['name', 'authorization'])

        const distance = !!params?.destination && _.find(validations, ['context.criteria', 'distance'])
        const night = !!params?.destination && _.find(validations, ['context.criteria', 'night'])
        const duration = _.find(validations, ['context.criteria', 'duration'])
        const stay = !!params?.destination && _.find(validations, ['context.criteria', 'stay'])
        const ridemode_info = _.find(_.filter(validations, ['type', 'info']), ['name', 'ridemode'])?.context

        setFormState({
          traffic_alert: traffic_alert?.context?.traffic_id || undefined,
          margin: traffic_alert?.context?.margin || undefined,
          unauthorized_schedule: !!unauthorized || undefined,
          ridemode_validation: distance || duration || stay || night || undefined,
          ridemode_info
        })
      } catch (err) {
        console.log('Error : ', err)
      }
    }
  }
}

export default Callbacks
