class Callbacks {
  static onFixedPriceChangeHandler(setFormValues) {
    return function onFixedPriceChange(value) {
      setFormValues([{ field: 'estimation_type', value: !!value ? 'fixed' : 'estimated' }])
      return
    }
  }
  static onFixedPriceClickHandler(setFormValues, fixed_rate) {
    return function onFixedPriceClick() {
      setFormValues([{ field: 'fixed_rate', value: !fixed_rate }])
      return
    }
  }
}

export default Callbacks
