import React from 'react'
import { I18n } from '@front/volcanion/'

import { Grid } from '@mui/material'

import { TextField, MenuSelectorField } from '@front/squirtle'

const ThirdRow = props =>
  <Grid container item xs={12}>
    <Grid item xs>
      <TextField
        name='contact.address'
        label={I18n.t('address.billing')}
        disabled
        InputLabelProps={{
          sx: {
            color: 'primary.main',
            fontSize: 15
          }
        }}
      />
    </Grid>
    <Grid item xs>
      <TextField
        name='contact.email'
        label={I18n.t('email.label', { count: 1 })}
        disabled
        InputLabelProps={{
          style: {
            color: 'primary.main',
            fontSize: 15
          }
        }}
      />
    </Grid>
  </Grid>


export default React.memo(ThirdRow)
