import React from 'react'
import { I18n } from '@front/volcanion'

import { Typography, Button, Box } from '@mui/material/'

const AddressFooter = ({ disableSources, getEnabledSources, ...props }) => {
  const enabledSources = getEnabledSources()
  const hidden = !(_.includes(enabledSources, 'recent') && _.includes(enabledSources, 'favorite'))
  return (
    <Box hidden={hidden}>
      <Button onClick={() => { disableSources(['recent', 'favorite']) }} sx={{ textTransform: 'none' }}>
        <Typography sx={{ ml: 1, textDecoration: 'underline' }}>{I18n.t('result.more')}</Typography>
      </Button>
    </Box>
  )
}
export default React.memo(AddressFooter)
