import React, { useCallback } from 'react'
import { hooks } from '@front/volcanion'
import { TableRow, TableHeader, SearchFrame, SearchForm, SearchTable, FormContent } from '@front/squirtle'

import Callbacks from './callbacks'

import Header from './Header'
import Row from './Row'
import Form from './Form'

const ContractList = props => {
  const navigate = hooks.useNavigate()
  const [selectedCompany] = hooks.useGlobalState('selectedCompany')
  const initialRoleFilter = hooks.useCompanyFilter(selectedCompany, { model_name: 'companycontract' })

  const filterToForm = useCallback(Callbacks.filterToFormHandler(), [])
  const formToFilter = useCallback(Callbacks.formToFilterHandler(initialRoleFilter), [initialRoleFilter])
  const filterToState = useCallback(Callbacks.filterToStateHandler(), [])

  const handleRowClick = useCallback((rowId, row) => navigate(`/admin/contract/${_.get(row, 'companycontract_id')}`), [])

  return <>
    <SearchFrame
      model_name={'companycontract'}
      populate={['formula', 'contact']}
      allowCreate={false}
    >
      <SearchForm
        filterToForm={filterToForm}
        formToFilter={formToFilter}
        filterToState={filterToState}
        submitOnMount
        submitWatchers={[selectedCompany]}
        getButtonStyleProps={() => ({ mt: 2, mb: 4 })}
      >
        <FormContent><Form /></FormContent>
      </SearchForm>
      <SearchTable
        tableProps={{
          sx: { border: 1, boderColor: 'primary.main' },
          enablePagination: false,
          onRowClick: handleRowClick
        }}
      >
        <TableHeader><Header /></TableHeader>
        <TableRow><Row /></TableRow>
      </SearchTable>
    </SearchFrame >
  </>
}

export default React.memo(ContractList)
