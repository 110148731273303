import React, { useCallback, useEffect } from 'react'
import { I18n, hooks } from '@front/volcanion'

const withContainer = Component => props => {
  const [, { openDialog }] = hooks.useDialogs()
  const [{ schedule_type }] = hooks.useFormState()
  const isMounted = hooks.useFormStatus('isMounted')
  const setFormValues = hooks.useFormChange()
  const [
    mode,
    requestedAt,
    source_details,
    destination_details,
    duration,
    load_type,
    selectedCommercialPackage_id,
    load_count,
    companyservice_id,
    vehicle_opts,
    driver_opts,
    returnAt
  ] = hooks.useFieldValues([
    'ridemode',
    'requestedAt',
    'source.details',
    'destination.details',
    'ridemode_duration',
    'load_type',
    'commercial_package',
    'load_count',
    'service',
    'vehicle_options',
    'driver_options',
    'returnAt'
  ])

  const [selectedPackage] = hooks.useModel('commercialpackage', [selectedCommercialPackage_id], { single: true })
  const [, authorized, { isLoading: isEstimationLoading }] = hooks.useEstimation({
    service: companyservice_id,
    requestedAt,
    returnAt,
    source_details,
    destination_details,
    mode,
    duration,
    vehicle_opts,
    driver_opts,
    schedule_type
  }, { isReady: isMounted })

  const cp_ids = _.compact(_.map(authorized, 'commercial_package_id'))
  const openPackageFormPopup = useCallback(() => openDialog('package_form'), [])


  useEffect(() => {
    if (!!isEstimationLoading) return _.noop
    const max_load = selectedPackage?.max_load
    if (!!max_load && max_load < load_count)
      openDialog(
        'incompatible_load_count_information',
        { message: I18n.t('load.incompatible.information', { max: max_load, current: load_count }) },
        { max_load, current: load_count, new_package: _.head(cp_ids), old_package: selectedCommercialPackage_id }
      )
    else if (!!selectedCommercialPackage_id && _.includes(cp_ids, selectedCommercialPackage_id))
      return
    else
      setFormValues([{ field: 'commercial_package', value: _.head(cp_ids), meta: { dirty: true } }])
  }, [isEstimationLoading, _.join(cp_ids, ',')])

  useEffect(() => {
    !!duration && setFormValues([{ field: 'ridemode_duration', value: null }])
  }, [mode])

  const mergedProps = {
    isEstimationLoading,
    cp_ids,
    load_type,
    openPackageFormPopup,
  }
  return (
    <Component {...mergedProps} />
  )
}
export default withContainer
