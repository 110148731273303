import { I18n } from '@front/volcanion'
import { UserUtils } from '@front/volcanion/utils'

import ValidationUtils from '@front/volcanion/utils/validation'

class Callbacks {
  static onCompanyuserChangeHandler(setFormValues, searchCompanyUser, cantBook) {
    return async function onCompanyuserChange(companyuser_id) {
      if (_.isEmpty(companyuser_id) || !!cantBook)
        return
      const companyusers = await searchCompanyUser({ companyuser_id })
      const companyuser = _.head(companyusers)
      const user = _.get(companyuser, 'user')
      const full_name = UserUtils.getFormatedName(companyuser?.contact)
      const updates = _.compact([
        { field: 'passenger_name', value: full_name },
        { field: 'passenger_number', value: user?.auth?.gsm }
      ])
      setFormValues(updates)
    }
  }

  static onGuestChangeHandler(setFormValues, passenger_info) {
    return async function onGuestChange(value) {
      setFormValues([
        { field: 'passenger_name', value: !!value ? undefined : passenger_info?.name, meta: { focus: !!value } },
        { field: 'passenger_number', value: !!value ? undefined : passenger_info?.number },
      ])
    }
  }

  static onDecrementLoadCountHandler(setFormValues, openNotification, load_count) {
    return async function onDecrementLoadCount() {
      if (load_count - 1 >= 1)
        setFormValues([{ field: 'load_count', value: load_count - 1 }])
      else
        openNotification(I18n.t('passenger.number.too_low'), { variant: 'error' })
    }
  }

  static onIncrementLoadCountHandler(setFormValues, openNotification, load_count, max_load, vehicle_count) {
    return async function onIncrementLoadCount() {
      if (load_count + 1 <= max_load || vehicle_count < 1)
        setFormValues([{ field: 'load_count', value: load_count + 1 }])
      else
        openNotification(I18n.t('passenger.number.too_hight', { max_load }), { variant: 'error' })
    }
  }

  static getCompanyuserFilterHandler() {
    return function getCompanyuserFilter(companyuser_id, company, companycontract, booking_level, self_booking, companyservice_id) {
      if (!booking_level && !!self_booking) return { companyuser_id }
      else if (booking_level === 'company' && !!self_booking) return { company }
      else if (booking_level === 'company' && !self_booking) return { company, companyuser_id: { '!': companyuser_id } }
      else if (booking_level === 'contract' && !!self_booking) return { services: { contract: companycontract } }
      else if (booking_level === 'contract' && !self_booking) return { services: { contract: companycontract }, companyuser_id: { '!': companyuser_id } }
      else if (booking_level === 'service' && !!self_booking) return { services: companyservice_id }
      else if (booking_level === 'service' && !self_booking) return { services: companyservice_id, companyuser_id: { '!': companyuser_id } }
      else return null
    }
  }

  static getCompanyuserValidateHandler(booking_level, self_booking) {
    return function getCompanyuserValidate() {
      return !!booking_level || !!self_booking
    }
  }

  static normalizePhoneHandler(CODE_INTERNATIONAL) {
    return function normalizePhone(value) {
      return ValidationUtils.parsePhone(value, CODE_INTERNATIONAL)
    }
  }

}

export default Callbacks
