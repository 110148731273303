import React, { useCallback } from 'react'
import { hooks } from '@front/volcanion'

const withContainer = Component => props => {
  const [{ displayRoundTrip }, setFormState] = hooks.useFormState()
  const ALLOW_EMPTY_DESTINATION = hooks.useRelayFeature('ALLOW_EMPTY_DESTINATION')
  const [reservationinfo_id, ridemode_id] = hooks.useFieldValues(['reservationinfo.reservationinfo_id', 'ridemode'])

  const [ridemode] = hooks.useModel('ridemode', [ridemode_id], { single: true, populate: ['config'] })

  const destination_required = !ridemode ? true : ridemode?.config?.display_destination_field
  const duration_required = !!ridemode?.duration_required && !ridemode?.config?.display_destination_field
  const stay_required = ridemode?.stay_required

  const handleDisplayRoundTripChange = useCallback(() => setFormState({ displayRoundTrip: !displayRoundTrip }), [setFormState, displayRoundTrip])

  const mergedProps = {
    stay_required,
    displayRoundTrip,
    destination_required,
    duration_required,
    reservationinfo_id,
    ALLOW_EMPTY_DESTINATION,
    handleDisplayRoundTripChange
  }

  return (
    <Component {...mergedProps} {...props} />
  )
}

export default withContainer