import React from 'react'
import { hooks, I18n, CDNSVG } from '@front/volcanion'

import { Box, Grid } from '@mui/material'
import { MenuSelectorField } from '@front/squirtle'

const EditIcon = props => <CDNSVG name='edit' fill='primary.main' category={'icons'} {...props} />

const PaymentSection = ({ service, setFormValues }) => {
  const [{
    payment_types,
  }] = hooks.useAuthorizedPaymentTypes({ service }, true, value => setFormValues([{ field: 'payment_type', value }]))

  return (
    <Grid item xs={12}>
      <Box sx={{ '&:hover': { backgroundColor: 'white' }, bgcolor: 'extraWhite', p: '3px', pr: 1, pl: 1, borderRadius: '4px', border: '1px solid', borderColor: 'rgba(61, 66, 94, 0.5)' }}>
        <MenuSelectorField
          name={'payment_type'}
          options={payment_types}
          labelKeys={['name_translated']}
          selectKeys={['paymenttype_id']}
          getOptionIcon={option => {
            switch (option?.value) {
              default:
                return 'credit_card'
            }
          }}
          getOptionIconProps={option => ({ size: 27 })}
          valueDecoratorProps={{
            sx: {
              fontSize: 15,
              color: 'text.label',
              mt: '3px'
            }
          }}
          sx={{ '.MuiSelect-icon': { color: 'primary.main', fontSize: 21 }, width: 1 }}
          selectProps={{ IconComponent: EditIcon }}
          selectFirst={false}
          disableUnderline
          required
          requiredText={I18n.t('payment.empty')}
          clearInvalid
        />
      </Box>
    </Grid>
  )
}

export default React.memo(PaymentSection)
