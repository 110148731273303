import React, { useEffect } from 'react'
import { hooks } from '@front/volcanion'

import { Loader } from '@front/squirtle'

const withContainer = Component => props => {
  const [selectedCompany] = hooks.useGlobalState('selectedCompany')
  const [company, { isLoading: isLoadingCompany }] = hooks.useModel('company', [selectedCompany], {
    populate: ['contact'],
    single: true
  })

  const mergedProps = {
    gsm: _.get(company, 'contact.gsm'),
    email: _.get(company, 'contact.email'),
    address: _.get(company, 'contact.address'),
  }

  return (<Loader isLoading={isLoadingCompany} size={20}>
    <Component {...mergedProps} {...props} />
  </Loader >
  )
}

export default withContainer