import React, { useEffect } from 'react'

import { hooks } from '@front/volcanion'
import { Loader } from '@front/squirtle'

const withContainer = Component => props => {
  const isReady = hooks.useFormStatus('isReady')
  const [client_id] = hooks.useFormState('client_id')
  const [selectedCompany] = hooks.useGlobalState('selectedCompany')
  const navigate = hooks.useNavigate()

  useEffect(() => {
    !!isReady && navigate('/admin/contracts')
  }, [selectedCompany])

  const mergedProps = {
    client_id
  }

  return (
    <Loader isLoading={!isReady}>
      <Component {...mergedProps} {...props} />
    </Loader>
  )
}

export default withContainer
