import React, { useState, useCallback } from 'react'
import { hooks } from '@front/volcanion'

const withContainer = Component => ({ ...props }) => {
  const navigate = hooks.useNavigate()
  const [quickOrder, setQuickOrder] = useState(true)

  const onQuickOrderChange = useCallback(() => {
    setQuickOrder(!quickOrder)
  }, [setQuickOrder, quickOrder])

  const mergedProps = {
    quickOrder,
    onQuickOrderChange,
    navigate
  }

  return (
    <Component {...mergedProps} />
  )
}

export default withContainer
