import React, { useCallback } from 'react'

import { hooks } from '@front/volcanion'

import PopupCallbacks from './popupCallbacks'

const withContainer = Component => props => {
  const user = hooks.useActiveUser()
  const [, { openDialog }] = hooks.useDialogs()
  const setFormValues = hooks.useFormChange()
  const [, setSelectedCompanyuser] = hooks.useFormState('companyuser')
  const [{ companyuser }, setFormState] = hooks.useFormState()
  const [
    commercial_package_id,
    vehicle_count,
    load_count,
    passenger_name,
    passenger_number,
    luggage,
    load_type,
    companyservice_id
  ] = hooks.useFieldValues(['commercial_package', 'vehicle_count', 'load_count', 'source.info.passenger_name', 'source.info.passenger_number', 'luggage', 'load_type', 'service'])


  const submitPassengerPopup = useCallback(PopupCallbacks.submitPassengerPopupHandler(setFormValues, setFormState), [setFormValues, setSelectedCompanyuser])

  const passengerPopupProps = {
    initialValues: {
      companyuser_id: !!companyservice_id ? companyuser : undefined,
      companyusers: !!companyservice_id ? [companyuser] : undefined,
      companyservice_id: companyservice_id,
      user_id: !!companyservice_id ? undefined : user?.user_id,
      commercial_package_id,
      vehicle_count,
      passenger_name,
      passenger_number,
      luggage,
      load_count
    }
  }

  const openPassengerPopup = useCallback(PopupCallbacks.openPassengerPopupHandler(openDialog), [openDialog])

  const mergedProps = {
    passengerPopupProps,
    load_type,
    disabledPassenger: vehicle_count > 1,
    vehicle_count,
    passenger_name,
    passenger_number,
    load_count,
    luggage,
    openPassengerPopup,
    submitPassengerPopup
  }

  return (
    <Component {...mergedProps} {...props} />
  )
}

export default withContainer