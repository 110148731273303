import React from 'react'
import { I18n } from '@front/volcanion'

import { TableCell, Box, Typography } from '@mui/material'


const TextHeader = ({ label, ...props }) =>
  <Box sx={{ display: 'flex', justifyContent: 'center' }}>
    <Typography sx={{ color: 'text.label', textAlign: 'center', fontSize: 15, fontWeight: 600 }}>
      {label}
    </Typography>
  </Box>

const Header = props => <>
  <TableCell align='center'>
    <TextHeader label={I18n.t('name.label', { count: 1 })} />
  </TableCell>
  <TableCell align='center'>
    <TextHeader label={I18n.t('code.label', { count: 1 })} />
  </TableCell>
  <TableCell align='center'>
    <TextHeader label={I18n.t('status.label', { count: 1 })} />
  </TableCell>
  <TableCell align='center'>
    <TextHeader label={I18n.t('email.label', { count: 1 })} />
  </TableCell>
  <TableCell align='center'>
    <TextHeader label={I18n.t('password.required')} />
  </TableCell>
  <TableCell align='center'>
    <TextHeader label={I18n.t('payment.deferred')} />
  </TableCell>
</>

export default React.memo(Header)
