import UserUtils from '@front/volcanion/utils/user'

class Callbacks {
  static recordToFormHandler() {
    return function recordToForm(record) {
      return {
        contact: {
          last_name: _.get(record, 'contact.last_name'),
          first_name: _.get(record, 'contact.first_name'),
          email: _.get(record, 'contact.email'),
          gsm: _.get(record, 'contact.gsm'),
          tel: _.get(record, 'contact.tel'),
          type: _.get(record, 'contact.type'),
          address: _.get(record, 'contact.address'),
        }
      }
    }
  }

  static getEmptyFormHandler() {
    return function getEmptyFormValues() {
      return {
      }
    }
  }

  static formToRecordHandler(company) {
    return function formToRecord(values, extra, meta) {
      return {
        company,
        contact: {
          last_name: _.get(values, 'contact.last_name'),
          first_name: _.get(values, 'contact.first_name'),
          email: _.get(values, 'contact.email'),
          gsm: _.get(values, 'contact.gsm'),
          tel: _.get(values, 'contact.tel'),
          type: _.get(values, 'contact.type'),
          address: _.get(values, 'contact.address'),
        }
      }
    }
  }

  static recordToStateHandler() {
    return function recordToState(record) {
      return {
      }
    }
  }

  static onSubmitSuccessHandler() {
    return async function onSubmitSuccess(result, values, extra, meta) {
      console.log("🚀 ~ file: callbacks.js ~ line 27 ~ Callbacks ~ onSubmitSuccess ~ result, values, extra, meta", result, values, extra, meta)

    }
  }
  static onSubmitFailedHandler() {
    return async function onSubmitFailed(err, values, extra, meta) {
      console.log("🚀 ~ file: callbacks.js ~ line 27 ~ Callbacks ~ onSubmitFailed ~ err, values, extra, meta", err, values, extra, meta)

    }
  }
}

export default Callbacks
