import React from 'react'
import Component from './component'
import withContainer from './container'

import Input from './Input'
import ReservationInfo from './ReservationInfo'

export {
  ReservationInfo,
  Input,
  Component,
  withContainer
}
export default React.memo(withContainer(Component))
