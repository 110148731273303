import React, { useMemo } from 'react'
import { hooks, I18n } from '@front/volcanion'

const withContainer = Component => ({ isLoading, openPackageFormPopup, ...rest }) => {
  const [
    source_details,
    destination_details,
    load_type,
    commercial_formula_id,
    commercial_package_id
  ] = hooks.useFieldValues(['source.details', 'destination.details', 'load_type', 'commercial_formula', 'commercial_package'])

  const [commercial_package] = hooks.useModel('commercialpackage', [commercial_package_id], { single: true })
  const [commercial_formula] = hooks.useModel('commercialformula', [commercial_formula_id], { single: true, populate: ['authorized_packages'] })

  const hasSource = !_.isEmpty(source_details)
  const hasDestination = !_.isEmpty(destination_details)

  const authorized_packages = _.filter(commercial_formula?.authorized_packages, ['load_type', load_type])

  const errorText = useMemo(() => {
    if (!!commercial_package_id || !!isLoading) return null
    else if (!!commercial_formula && _.isEmpty(authorized_packages)) return I18n.t('package.no_authorized_packages', { load_type: I18n.t(`load.type.${load_type}`) })
    else if (!!hasSource && !!hasDestination) return I18n.t('package.no_destination_covered')
    else if (!!hasSource && load_type === 'user') return I18n.t('package.no_source_covered')
    else null
  }, [hasSource, hasDestination, commercial_package_id, isLoading, _.size(authorized_packages), commercial_formula?.commercial_formula_id])

  const name_translated = useMemo(() => {
    if (!!hasSource) {
      if (!commercial_package_id) return I18n.t('package.no_package')
      else return commercial_package?.name_translated
    }
    else return I18n.t('address.source_required')
  })

  const mergedProps = {
    openPackageFormPopup,
    hasPackage: !!commercial_package,
    client_type_icon: commercial_package?.client_type_icon || 'default',
    name_translated: name_translated,
    title_translated: commercial_package?.title_translated,
    load_type: commercial_package?.load_type,
    max_load: commercial_package?.max_load,
    errorText
  }

  return (
    <Component {...mergedProps} />
  )
}

export default withContainer
