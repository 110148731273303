import React from 'react'
import { Section, I18n } from '@front/volcanion'

import { Grid } from '@mui/material'

import DialogManager from './Popup'

import {
  AccountSection,
  AddressSection,
  AddressInfoSection,
  CommentSection,
  DateSection,
  FixedRateSection,
  FooterSection,
  LoadTypeSection,
  OptionSection,
  PackageSection,
  PassengerSection,
  PaymentSection,
  RefSection,
  RidemodeSection
} from './Section'


const OrderDetails = ({
  destination_enabled,
  duration_required,
  hiddeFixedRate,
  isUserLoadType,
  hasSelectedPackage,
  displayRoundTrip,
  checkReservationInfo,
  ...props
}) => <>
    <DialogManager {...props} />
    <Grid container rowSpacing={1}>
      <AccountSection />
      <RidemodeSection />
      <LoadTypeSection />
      <AddressSection checkReservationInfo={checkReservationInfo} />
      <Grid container item xs={6} rowSpacing={1}>
        <DateSection name='requestedAt' title={!!displayRoundTrip && I18n.t('date.start')} checkReservationInfo={checkReservationInfo} />
        {!!displayRoundTrip && <DateSection name='returnAt' title={I18n.t('date.return')} />}
        <PassengerSection />
        <Grid item xs={12} hidden={!!isUserLoadType}><Section name={'source'}><AddressInfoSection /></Section></Grid>
        <Grid item xs={12} hidden={!!isUserLoadType}><Section name={'destination'}><AddressInfoSection /></Section></Grid>
        {!!isUserLoadType && <PaymentSection />}
        {!!isUserLoadType && !hiddeFixedRate && <FixedRateSection />}
        {!!isUserLoadType && <RefSection />}
      </Grid>
      <PackageSection />

      {!isUserLoadType && <Grid item xs={6}><PaymentSection /></Grid>}
      {!isUserLoadType && <Grid item xs={6}><RefSection /></Grid>}
      {!!hasSelectedPackage && !!isUserLoadType && <OptionSection />}

      <CommentSection />
      <FooterSection />
    </Grid>
  </>

export default React.memo(OrderDetails)
