import React from 'react'

import { Box, Stack } from '@mui/material'

import Header from '@onix/elements/headers/admin'
import CardHeader from './Section/CardHeader'
import CompanyInfo from './Section/CompanyInfo'
import Menu from './Section/Menu'

const AdminFrame = props =>
  <Box sx={{ heigth: 1, mt: 2 }}>
    <Stack spacing={6}>
      <Header {...props} />
      <CardHeader />
      <CompanyInfo />
      <Menu />
    </Stack>
  </Box>

export default React.memo(AdminFrame)
