import React, { useEffect, useCallback } from 'react'
import { hooks } from '@front/volcanion'

import HookUtils from '@front/volcanion/utils/hooks'

import PopupCallbacks from './popupCallbacks'

const withContainer = Component => props => {
  const [savedPackage, setsavedPackage] = hooks.useFormState('saved_package')
  const [, { openDialog }] = hooks.useDialogs()
  const setFormValues = hooks.useFormChange()
  const [user_id] = hooks.useFormState('user_id')
  const [commercial_package, driver_options, vehicle_options, service, load_type] = hooks.useFieldValues(['commercial_package', 'driver_options', 'vehicle_options', 'service', 'load_type'])
  const [cp, { isReady: isReadyPackage, isLoading: isLoadingPackage }] = hooks.useModel('commercialpackage', [commercial_package], { populate: ['allowed_driver_options', 'allowed_vehicle_options'], single: true })

  const isPackagePopulate = !!cp && !!isReadyPackage && !isLoadingPackage
  const [default_vehicle_options, searchDefaultVehicleOption, { isReady: isReadyDefaultVehicle, isLoading: isLoadingDefaulVehicle }] = hooks.useModelSearch('vehicleoption', 'get', { mode: 'default' })
  const [default_driver_options, searchDefaultDriverOption, { isReady: isDefaultDriverReady, isLoading: isLoadingDefaultDriver }] = hooks.useModelSearch('useroption', 'get', { mode: 'default' })

  const allowed_driver_opts = _.map(_.filter(_.get(cp, 'allowed_driver_options'), ['customer_display', true]), 'user_option_id')
  const allowed_vehicle_opts = _.map(_.filter(_.get(cp, 'allowed_vehicle_options'), ['customer_display', true]), 'vehicle_option_id')

  const custom_driver_options = _.map(_.filter(default_driver_options, ['customer_display', true]), 'user_option_id')
  const custom_vehicle_options = _.map(_.filter(default_vehicle_options, ['customer_display', true]), 'vehicle_option_id')

  const vehicle_options_ids = _.uniq(_.flatten([allowed_vehicle_opts, custom_vehicle_options, vehicle_options]))
  const driver_options_ids = _.uniq(_.flatten([allowed_driver_opts, custom_driver_options, driver_options]))

  const driver_missing_options = _.difference(driver_options, _.flatten([allowed_driver_opts, custom_driver_options]))
  const vehicle_missing_options = _.difference(vehicle_options, _.flatten([allowed_vehicle_opts, custom_vehicle_options]))

  const driver_diff = _.filter(driver_options, opt => _.includes(allowed_driver_opts, opt))
  const vehicle_diff = _.filter(vehicle_options, opt => _.includes(allowed_vehicle_opts, opt))

  const openOptionPopup = useCallback(PopupCallbacks.openOptionPopupHandler(openDialog), [openDialog])
  const cancelOptionPopup = useCallback(PopupCallbacks.cancelOptionPopupHandler(setFormValues, savedPackage, driver_options, vehicle_options), [setFormValues, savedPackage, driver_options, vehicle_options])
  const confirmOptionPopup = useCallback(PopupCallbacks.confirmOptionPopupHandler(setFormValues, setsavedPackage, commercial_package, driver_diff, vehicle_diff), [setFormValues, setsavedPackage, commercial_package, driver_diff, vehicle_diff])

  const mergedProps = {
    confirmOptionPopup,
    cancelOptionPopup,
    vehicle_options_ids,
    driver_options_ids,
    load_type,
    commercial_package
  }

  useEffect(() => {
    if (!!commercial_package) {
      const filter_opts = {
        commercial_package_id: commercial_package,
        service_id: service,
        user_id
      }
      searchDefaultVehicleOption(filter_opts)
      searchDefaultDriverOption(filter_opts)
    }
  }, [commercial_package, service])

  useEffect(() => {
    if (!!isReadyDefaultVehicle && !isLoadingDefaulVehicle) {
      setFormValues([{ field: 'vehicle_options', value: _.compact(_.uniq(_.flatten([custom_vehicle_options, vehicle_missing_options, vehicle_options]))) }])
    }
  }, [isReadyDefaultVehicle, isLoadingDefaulVehicle])

  useEffect(() => {
    if (!!isDefaultDriverReady && !isLoadingDefaultDriver)
      setFormValues([{ field: 'driver_options', value: _.compact(_.uniq(_.flatten([custom_driver_options, driver_missing_options, driver_options]))) }])
  }, [isDefaultDriverReady, isLoadingDefaultDriver])


  useEffect(() => {
    if (!commercial_package || !isPackagePopulate) return _.noop
    if (!savedPackage && !!cp) setsavedPackage(_.get(cp, 'commercial_package_id'))
    if (!_.isEmpty(_.compact(driver_missing_options)) || !_.isEmpty(_.compact(vehicle_missing_options))) {
      openOptionPopup(driver_missing_options, vehicle_missing_options)
    }
  }, [isPackagePopulate, commercial_package, _.flatten([vehicle_missing_options, driver_missing_options]).join(','), service])

  return (
    <Component {...mergedProps} {...props} />
  )
}

export default withContainer