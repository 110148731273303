import { UserUtils } from "@front/volcanion/utils"

class Callbacks {
  static afterChangeHandler(setFormValues, setFormState, user) {
    return function afterChange(value) {
      if (!value) {
        const customer_name = UserUtils.getFormatedName({ ...user?.info, designation: user?.customerinfo?.name })
        const customer_number = user?.auth?.gsm
        setFormState({ companyuser: undefined })
        setFormValues([
          { field: 'passenger_name', value: customer_name },
          { field: 'passenger_number', value: customer_number },
          { field: 'source.info.passenger_name', value: customer_name },
          { field: 'source.info.passenger_number', value: customer_number },
          { field: 'destination.info.passenger_name', value: customer_name },
          { field: 'destination.info.passenger_number', value: customer_number },
          { field: 'commercial_formula', value: user?.commercial_formula }
        ])
      } else {
        _.find(user?.user_companies, ({ services, self_booking, companyuser_id, contact }) => {
          const matched = _.find(services, ['companyservice_id', value])

          if (!!matched) {
            const companyuser_name = UserUtils.getFormatedName({ ...contact, designation: user?.customerinfo?.name })
            const companyuser_number = user?.auth?.gsm
            setFormState({ companyuser: companyuser_id })
            setFormValues(!self_booking
              ? [
                { field: 'passenger_name', value: undefined },
                { field: 'passenger_number', value: undefined },
                { field: 'source.info.passenger_name', value: undefined },
                { field: 'source.info.passenger_number', value: undefined },
                { field: 'destination.info.passenger_name', value: undefined },
                { field: 'destination.info.passenger_number', value: undefined },
                { field: 'commercial_formula', value: matched?.contract?.formula }
              ]
              : [
                { field: 'commercial_formula', value: matched?.contract?.formula },
                { field: 'passenger_name', value: companyuser_name },
                { field: 'passenger_number', value: companyuser_number },
                { field: 'source.info.passenger_name', value: companyuser_name },
                { field: 'source.info.passenger_number', value: companyuser_number },
                { field: 'destination.info.passenger_name', value: companyuser_name },
                { field: 'destination.info.passenger_number', value: companyuser_number },
              ]
            )
          }
        })
      }
    }
  }
}

export default Callbacks
