
class Callbacks {
  static filterToFormHandler() {
    return function filterToForm(filter) {
      return {
        status: null,
        ...filter
      }
    }
  }
  static filterToStateHandler() {
    return function filterToState(state) {
      return {
      }
    }
  }
  static formToFilterHandler(initialRoleFilter) {
    return function formToFilter(values, extra, meta, state) {
      return {
        ...values,
        status: !!_.get(values, 'status') ? [{ name: _.get(values, 'status') }] : undefined,
        ...initialRoleFilter,
      }
    }
  }
}

export default Callbacks
