import React from 'react'
import { I18n } from '@front/volcanion'

import { Box, Grid } from '@mui/material'
import { ModelMenuSelectorField, ConfirmationDialog, DialogContent } from '@front/squirtle'

import { OptionDialog } from '../../Popup/'

const OptionSection = ({
  confirmOptionPopup,
  cancelOptionPopup,
  driver_options_ids,
  vehicle_options_ids
}) =>
  <Grid container item xs={12} >
    <ConfirmationDialog
      name={'option_confirmation'}
      title={I18n.t('option.missing.label', { count: 2 })}
      onConfirm={confirmOptionPopup}
      onCancel={cancelOptionPopup}
      noLabel={I18n.t('action.cancel')}
      yesLabel={I18n.t('action.continue')}
    >
      <DialogContent>
        <OptionDialog />
      </DialogContent>
    </ConfirmationDialog>
    <Grid container spacing={1} justifyContent={'flex-end'}>
      <Grid item xs={6} hidden={_.isEmpty(vehicle_options_ids)}>
        <Box sx={{ '&:hover': { backgroundColor: 'white' }, bgcolor: 'extraWhite', p: '3px', borderRadius: '4px', border: '1px solid', borderColor: 'rgba(61, 66, 94, 0.5)' }}>
          <ModelMenuSelectorField
            name={'vehicle_options'}
            label={I18n.t('option.vehicleoption.label', { count: 1 })}
            model_name={'vehicleoption'}
            config={{
              ids: vehicle_options_ids,
              mutator: elem => ({ label: `${elem?.name_translated} ${elem?.price ? `+${elem?.price}${I18n.t('euro.symbol')}` : ''}`, ...elem })
            }}
            disableUnderline
            multiple
            disabled={_.isEmpty(vehicle_options_ids)}
            labelProps={{
              sx: { fontSize: 15 }
            }}
            allowNone={false}
          />
        </Box>
      </Grid>
      <Grid item xs={6} hidden={_.isEmpty(driver_options_ids)}>
        <Box sx={{ '&:hover': { backgroundColor: 'white' }, bgcolor: 'extraWhite', p: '3px', borderRadius: '4px', border: '1px solid', borderColor: 'rgba(61, 66, 94, 0.5)' }}>
          <ModelMenuSelectorField
            name={'driver_options'}
            label={I18n.t('option.useroption.label', { count: 1 })}
            model_name={'useroption'}
            config={{
              ids: driver_options_ids,
              mutator: elem => ({ label: `${elem?.name_translated} ${elem?.price ? `+${elem?.price}${I18n.t('euro.symbol')}` : ''}`, ...elem })
            }}
            disabled={_.isEmpty(driver_options_ids)}
            disableUnderline
            multiple
            labelProps={{
              sx: { fontSize: 15 }
            }}
            allowNone={false}
          />
        </Box>
      </Grid>
    </Grid>
  </Grid>
export default React.memo(OptionSection)
