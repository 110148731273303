
import React from 'react'
import { I18n } from '@front/volcanion'

import { Box, Typography } from '@mui/material'

import moment from 'moment'

const InvalidDateTime = ({ requestedAt, minimumDate, maximumDate, reason }) => (
  <Box>
    <Typography fontSize={16}>
      {reason === 'immediate' && I18n.t('dialog.information_immediate_forbidden.prefix')}
      {reason !== 'immediate' && `${I18n.t('dialog.information_invalid_datetime.selected_start')} ${_.toLower(I18n.t('prefix.the'))}`}
      {reason !== 'immediate' && <Box component="span" ml={'4px'} fontWeight='bold'>{moment(requestedAt).format(`DD/MM [${I18n.t('prefix.at')}] HH:mm`)}</Box>}

      {`. ${I18n.t('dialog.information_invalid_datetime.booking_between')} ${_.toLower(I18n.t('prefix.the'))}`}
      <Box component="span" mr={'4px'} ml={'4px'} fontWeight='bold'>{moment(minimumDate).format(`DD/MM [${I18n.t('prefix.at')}] HH:mm`)}</Box>
      {I18n.t(`prefix.and`)} {_.toLower(I18n.t('prefix.the'))}
      <Box component="span" ml={'4px'} fontWeight='bold'>{moment(maximumDate).format(`DD/MM [${I18n.t('prefix.at')}] HH:mm`)}</Box>
      {'.'}
    </Typography>

  </Box >
)

export default InvalidDateTime
