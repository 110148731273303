import React from 'react'
import Component from './component'
import withContainer from './container'

import PoiAdditionalInfo from './PoiAdditionalInfo'
import PoiAdditionalInfoForm from './PoiAdditionalInfoForm'
import OptionDialog from './Option'
import ResumeBooking from './Resume'
import TrafficAlertDialog from './TrafficAlert'
import UnauthorizedScheduleDialog from './UnauthorizedSchedule'
import IncompatibleLoadCountDialog from './IncompatibleLoadCount'
import InvalidDateTimeDialog from './InvalidDateTime'

export {
  Component,
  withContainer,
  PoiAdditionalInfo,
  PoiAdditionalInfoForm,
  OptionDialog,
  ResumeBooking,
  TrafficAlertDialog,
  UnauthorizedScheduleDialog,
  IncompatibleLoadCountDialog,
  InvalidDateTimeDialog
}

export default React.memo(withContainer(Component))
