import React, { useMemo } from 'react'
import { hooks } from '@front/volcanion'

import StatusUtils from '@onix/utils/status'

const withContainer = Component => props => {
  const [status] = hooks.useFieldValues(['status'])

  const mergedProps = {
    color: StatusUtils.getColor(status)
  }

  return (
    <Component {...mergedProps} {...props} />
  )
}

export default withContainer
