import React from 'react'

import { Box, Stack, Typography } from '@mui/material'


const Title = ({ title, ...props }) =>
  <Box sx={{ borderLeft: 4, borderColor: 'primary.main', mt: 1, }}>
    <Typography sx={{ fontSize: 'h5.fontSize', color: 'primary.main', p: 2, fontWeight: 300 }}>
      {title}
    </Typography>
  </Box>


const IncompatibleLoadCount = ({ message, ...props }) => <>
  <Stack spacing={2}>
    <Title {...props} />
    <Typography> {message} </Typography>
  </Stack>
</>

export default React.memo(IncompatibleLoadCount)
