import React, { useCallback } from 'react'
import { hooks } from '@front/volcanion'

import { TableRow, TableHeader, SearchFrame, SearchForm, SearchTable, FormContent } from '@front/squirtle'

import Callbacks from './callbacks'

import Row from './Row'
import Header from './Header'
import Form from './Form'

const OrderList = props => {
  const [selectedCompany] = hooks.useGlobalState('selectedCompany')
  const initialRoleFilter = hooks.useCompanyFilter(selectedCompany, { model_name: 'order' })

  const filterToForm = useCallback(Callbacks.filterToFormHandler(), [])
  const formToFilter = useCallback(Callbacks.formToFilterHandler(initialRoleFilter), [initialRoleFilter])
  const filterToState = useCallback(Callbacks.filterToStateHandler(), [])

  return <>
    <SearchFrame
      model_name={'order'}
      populate={['service', 'quotation', 'loads', 'assigned_transport.driver.info', 'source', 'info', 'destination', 'current_target.eta', 'service.contract']}
      default_limit={15}
      watch_type='index'
      sort={['requestedAt DESC']}
      allowCreate={false}
    >
      <SearchForm
        submitOnMount
        submitWatchers={[selectedCompany]}
        disableButton
        filterToForm={filterToForm}
        formToFilter={formToFilter}
        filterToState={filterToState}
      >
        <FormContent><Form /></FormContent>
      </SearchForm>
      <SearchTable
        tableProps={{
          sx: { border: 1, boderColor: 'primary.main' },
          enablePagination: false,
          onRowClick: null
        }}
      >
        <TableHeader><Header /></TableHeader>
        <TableRow><Row /></TableRow>
      </SearchTable>
    </SearchFrame>
  </>
}

export default React.memo(OrderList)
