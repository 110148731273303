import React from 'react'
import { I18n } from '@front/volcanion'

import { ConfirmationDialog, DialogContent, } from '@front/squirtle'

import Companyservice from '@onix/frames/Service'

const DialogManager = ({
  onConfirmCompanyservice
}) => (
  <>

    <ConfirmationDialog
      name={'generate_service'}
      maxWidth={'lg'}
      onConfirm={(state, extra) => onConfirmCompanyservice(extra)}
      disableOnClose
      yesLabel={I18n.t('action.save')}
      noLabel={I18n.t('action.cancel')}
    >
      <DialogContent> <Companyservice /> </DialogContent>
    </ConfirmationDialog>
  </>
)

export default React.memo(DialogManager)
