import React from 'react'
import { I18n, CDNSVG } from '@front/volcanion'

import { Collapse, Box, Typography } from '@mui/material'
import { TextField } from '@front/squirtle'

const SearchForm = props =>
  <Collapse in timeout={1000}>
    <Box sx={{ my: 2 }}>
      <Box sx={{ display: 'flex', alignItems: 'center', p: 2, boxShadow: 6 }}>
        <Box>
          <CDNSVG name='gauge' fill={'primary.main'} category={'icons'} />
        </Box>
        <Box sx={{ ml: 2 }}>
          <Typography sx={{ fontSize: 21, color: 'primary.main', letterSpacing: 1.5 }}> {I18n.t('order.live')}: </Typography>
        </Box>
      </Box>
      <Box sx={{ display: 'none' }}>
        <TextField name='test' />
      </Box>
    </Box>
  </Collapse>



export default React.memo(SearchForm)
