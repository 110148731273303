
import React from 'react'
import { I18n } from '@front/volcanion'

import { Grid, Stack } from '@mui/material'

import FormHeader from '@onix/elements/headers/form'

import FirstRow from './Row/First'
import SecondRow from './Row/Second'
import ThirdRow from './Row/Third'
import FourthRow from './Row/Fourth/component'
import FifthRow from './Row/Fifth'
import ServicesSection from './Row/Services'
import Popup from './Popup'

const Detail = props => <>
  <Grid container justifyContent='center' alignItems='center'>
    <Grid container item xs={11} rowSpacing={3} sx={{ boxShadow: 6, borderRadius: 3, p: 3 }}>
      <FirstRow />
      <SecondRow />
      <ThirdRow />
      <FourthRow />
      <FifthRow />
      <ServicesSection />
    </Grid>
  </Grid>
</>

const CompanyContract = ({ client_id }) =>
  <Stack spacing={8}>
    <Popup />
    <FormHeader
      isSvg
      name={'contracts'}
      goBackPath={'/admin/contracts'}
      title={I18n.t('companycontract.detail', { client_id })}
      enableActions={false}
    />
    <Detail />
  </Stack>

export default React.memo(CompanyContract)
