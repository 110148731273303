import React, { useCallback } from 'react'
import { hooks, I18n } from '@front/volcanion'

import PopupCallbacks from './popupCallbacks'

const withContainer = Component => props => {
  const [, { openDialog }] = hooks.useDialogs()
  const setFormValues = hooks.useFormChange()
  const prefix = hooks.useSectionName()
  const popupName = `${prefix}_address_info_form`
  const [
    dest_resident_name,
    dest_passenger_name,
    dest_passenger_number,
    src_resident_name,
    src_passenger_name,
    src_passenger_number
  ] = hooks.useFieldValues([
    'destination.info.resident_name', 'destination.info.passenger_name', 'destination.info.passenger_number',
    'source.info.resident_name', 'source.info.passenger_name', 'source.info.passenger_number'
  ])

  const resident_name = prefix === 'source' ? src_resident_name : dest_resident_name
  const passenger_name = prefix === 'source' ? src_passenger_name : dest_passenger_name
  const passenger_number = prefix === 'source' ? src_passenger_number : dest_passenger_number
  const iconColor = prefix === 'source' ? 'success' : 'error'
  const title = prefix === 'source' ? I18n.t('address.pickup.label', { count: 1 }) : I18n.t('address.dropoff.label', { count: 1 })
  
  const openAddressInfoPopup = useCallback(PopupCallbacks.openAddressInfoPopupHandler(openDialog, popupName, prefix), [openDialog, popupName, prefix])
  const submitAddressInfoPopup = useCallback(PopupCallbacks.submitAddressInfoPopupHandler(setFormValues, prefix), [setFormValues, prefix])

  const mergedProps = {
    title,
    resident_name,
    passenger_name,
    passenger_number,
    popupName,
    iconColor,
    openAddressInfoPopup,
    submitAddressInfoPopup
  }

  return (
    <Component {...mergedProps} {...props} />
  )
}

export default withContainer