import React, { useCallback } from 'react'
import { hooks, I18n } from '@front/volcanion'

import moment from 'moment'

import Callbacks from './callbacks'
import { TimeUtils } from '@front/volcanion/utils'

const withContainer = Component => ({ checkReservationInfo, title, name }) => {
  const [threshold_RA] = hooks.useSetting('threshold_RA')
  const [{ schedule_type }, setFormState] = hooks.useFormState()
  const [requestedAt, reservationinfo, commercial_package, source_details] = hooks.useFieldValues(['requestedAt', 'reservationinfo', 'commercial_package', 'source_details'])
  const [cp] = hooks.useModel('commercialpackage', [commercial_package], { single: true })

  const afterChange = useCallback(Callbacks.afterChangeHandler(checkReservationInfo, reservationinfo, requestedAt, setFormState, threshold_RA, schedule_type), [checkReservationInfo, reservationinfo, requestedAt, setFormState, threshold_RA, schedule_type])

  const immediateAllowed = cp?.immediate_order_enabled
  const minDateTime = cp?.min_delay && !immediateAllowed ? moment().add(cp?.min_delay, 'minutes') : undefined
  const maxDateTime = cp?.max_delay ? moment().add(cp?.max_delay, 'minutes') : undefined

  const finalRequestedAt = schedule_type === 'planned' ? moment(TimeUtils.getDetailsMomentFront(requestedAt, source_details)).format('DD/MM/YYYY HH:mm') : I18n.t('order.immediate.label.alter')

  const mergedProps = {
    immediateAllowed,
    finalRequestedAt,
    title,
    name,
    afterChange,
    minDateTime,
    maxDateTime
  }


  return (
    <Component {...mergedProps} />
  )
}

export default withContainer