import moment from 'moment'

class PopupCallbacks {
  static getEtaHandler(getDetails, setEta) {
    return async function getEta(order) {
      try {
        const { eta, ...details } = await getDetails({
          filter: {
            ..._.pick(order?.source, ['place_id', 'address_id', 'poi_client_id', 'lat', 'lng', 'coordinates', 'poi_id'])
          },
          owner: order?.do,
          options: {
            eta: true,
            commercial_package: order?.commercial_package.commercial_package_id,
            driver_opts: _.map(order?.driver_opts, 'user_option_id'),
            vehicle_opts: _.map(order?.vehicle_opts, 'vehicle_option_id'),
            load_count: order?.passenger_count,
            has_luggage: order?.has_luggage
          }
        })

        if (order?.schedule_type === 'planned') {
          setEta(_.toNumber(order?.info?.margin))
        } else {
          setEta(moment.utc(eta).diff(moment.utc(order?.requestedAt), 'minutes') + _.toNumber(order?.info?.margin))
        }
      } catch (err) {
        console.error('ETA ERR : ', err)
      }
      return {}
    }
  }


}

export default PopupCallbacks
