import React from 'react'
import { I18n } from '@front/volcanion/'

import { Box, Grid } from '@mui/material'

import { TextField, MenuSelectorField } from '@front/squirtle'

const Row1 = props =>
  <Grid container item xs={12} spacing={1}>
    <Grid item xs>
      <MenuSelectorField
        name={'contact.type'}
        label={I18n.t('type.label', { count: 1 })}
        options={[
          { label: 'director', value: 'director' },
          { label: 'finance', value: 'finance' },
          { label: 'marketing', value: 'marketing' },
          { label: 'sales', value: 'sales' },
          { label: 'billing', value: 'billing' }
        ]}
        selectFirst={false}
        allowNone={false}
        required
      />
    </Grid>
    <Grid item xs>
      <TextField
        name='contact.last_name'
        label={I18n.t('name.label', { count: 1 })}
        required
      />
    </Grid>
    <Grid item xs>
      <TextField
        name='contact.first_name'
        label={I18n.t('first_name.label', { count: 1 })}
        required
      />
    </Grid>
  </Grid>

const Row2 = props =>
  <Grid item container xs={8} spacing={1}>
    <Grid item xs>
      <TextField
        name={'contact.gsm'}
        label={I18n.t('gsm.mobile')}
        required
      />
    </Grid>
    <Grid item xs>
      <TextField
        name={'contact.tel'}
        label={I18n.t('gsm.fix')}
        required
      />
    </Grid>
  </Grid >

const Row3 = props =>
  <Grid container item xs={12} spacing={1}>
    <Grid item xs={4}>
      <TextField
        name='contact.email'
        label={I18n.t('email.label', { count: 1 })}
        required
      />
    </Grid>
    <Grid item xs>
      <TextField
        name='contact.address'
        label={I18n.t('address.label', { count: 1 })}
        required
      />
    </Grid>
  </Grid>


const PrimarySection = props => <>
  <Box sx={{ p: 2, border: 1, borderColor: 'text.label', borderRadius: 1 }}>
    <Grid container rowSpacing={2}>
      <Row1 />
      <Row2 />
      <Row3 />
    </Grid>
  </Box>
</>

export default React.memo(PrimarySection)
