import React, { useCallback, useEffect, useMemo } from 'react'
import { hooks } from '@front/volcanion'

import Callbacks from './callbacks'

const withContainer = Component => props => {
  //TODO NEW VERSION BROKEN NEW FORM 
  const identity = hooks.useIdentity()
  const { openNotification } = hooks.useNotification()
  const setFormValues = hooks.useFormChange()
  const CODE_INTERNATIONAL = hooks.useRelayConstant('CODE_INTERNATIONAL')
  const [user] = hooks.useActiveUser({ populate: ['info', 'customerinfo', 'auth'] })
  const [cantBook, setCantBook] = hooks.useFormState('cantBook')

  const [guest, load_count, companyuser_id, companyservice_id, commercial_package_id, vehicle_count] = hooks.useFieldValues(['guest', 'load_count', 'companyuser_id', 'companyservice_id', 'commercial_package_id', 'vehicle_count'])

  const [, searchCompanyUser] = hooks.useModelSearch('companyuser', 'get', { populate: ['user.info', 'user.auth', 'user.customerinfo', 'contact'] })
  const [ownCompanyUser, searchOwnCompanyUser] = hooks.useModelSearch('companyuser', 'get', { single: true })
  const [passenger_info] = hooks.useUserInfo(user?.user_id, companyservice_id)

  const [companyservice] = hooks.useModel('companyservice', [companyservice_id], { single: true, populate: ['contract'] })
  const [cp] = hooks.useModel('commercialpackage', [commercial_package_id], { single: true })

  const max_load = _.get(cp, 'max_load') || 4
  const guest_booking = _.get(ownCompanyUser, 'guest_booking')
  const booking_level = _.get(ownCompanyUser, 'booking_level')
  const self_booking = _.get(ownCompanyUser, 'self_booking')
  const companycontract = _.get(companyservice, 'contract.companycontract_id')
  const company = _.get(ownCompanyUser, 'company')

  const onGuestChange = useCallback(Callbacks.onGuestChangeHandler(setFormValues, passenger_info), [setFormValues, passenger_info])
  const onDecrementLoadCount = useCallback(Callbacks.onDecrementLoadCountHandler(setFormValues, openNotification, load_count), [setFormValues, openNotification, load_count])
  const onIncrementLoadCount = useCallback(Callbacks.onIncrementLoadCountHandler(setFormValues, openNotification, load_count, max_load, vehicle_count), [setFormValues, openNotification, load_count, max_load, vehicle_count])
  const onCompanyuserChange = useCallback(Callbacks.onCompanyuserChangeHandler(setFormValues, searchCompanyUser, cantBook), [setFormValues, searchCompanyUser, cantBook])
  const getCompanyuserFilter = useCallback(Callbacks.getCompanyuserFilterHandler(), [])
  const getCompanyuserValidate = useCallback(Callbacks.getCompanyuserValidateHandler(booking_level, self_booking), [booking_level, self_booking])
  const normalizePhone = useCallback(Callbacks.normalizePhoneHandler(CODE_INTERNATIONAL), [CODE_INTERNATIONAL])

  const companyuser_watchers = [ownCompanyUser?.companyuser_id, company, companycontract, booking_level, self_booking, companyservice_id]

  const disabledPassenger = useMemo(() => {
    if (vehicle_count > 1) return true
    else if (!!guest) return false
    else return !!companyuser_id
  }, [companyuser_id, vehicle_count, guest, companyuser_id, booking_level, guest_booking])

  const displayGuestField = useMemo(() => {
    if (vehicle_count > 1) return true
    else if (!!guest_booking) return true
    else return false
  }, [vehicle_count, guest_booking])

  const display_passenger_list = useMemo(() => {
    if (!!booking_level && !guest) return true
    else return false
  }, [guest, booking_level])


  const display_passenger_text = useMemo(() => {
    if (((!booking_level || !!guest) && (!!self_booking || !!guest_booking)) || !companyuser_id) return true
  }, [guest_booking, guest, self_booking, booking_level])

  useEffect(() => {
    setCantBook(!booking_level && !guest_booking && !self_booking && !!companyuser_id)
  }, [booking_level, guest_booking, self_booking, companyuser_id])

  useEffect(() => {
    if (!!_.get(user, 'user_id') && _.get(companyservice, 'contract.company')) {
      searchOwnCompanyUser({ user: user.user_id, company: companyservice.contract.company })
    }
  }, [_.get(user, 'user_id'), _.get(companyservice, 'contract.company')])

  const mergedProps = {
    identity,
    cantBook,
    guest_booking,
    getCompanyuserFilter,
    getCompanyuserValidate,
    companyuser_watchers,
    guest,
    load_count,
    max_load,
    booking_level,
    display_passenger_text,
    display_passenger_list,
    disabledPassenger,
    displayGuestField,
    onCompanyuserChange,
    onDecrementLoadCount,
    onIncrementLoadCount,
    onGuestChange,
    normalizePhone
  }

  return (
    <Component {...mergedProps} {...props} />
  )
}

export default withContainer
