import React from 'react'
import { I18n } from '@front/volcanion'

import { Box, Grid } from '@mui/material'
import { TextField } from '@front/squirtle'

const CommentSection = ({ hidden, count, ...props }) =>
  <Grid container item xs={12} hidden={hidden}>
    <Box sx={{
      '&:hover': { backgroundColor: 'white'},
      bgcolor: 'extraWhite',
      p: '3px',
      pr: 1,
      pl: 1,
      borderRadius: '4px',
      border: '1px solid',
      borderColor: 'rgba(61, 66, 94, 0.5)',
      width: 1
    }}>
      <TextField
        label={I18n.t('comment.length.driver', { count })}
        name={'comment'}
        multiline
        minRows={1}
        InputProps={{
          disableUnderline: true,
          inputProps: { style: { fontSize: 13 } }
        }}
      />
    </Box>
  </Grid >


export default React.memo(CommentSection)