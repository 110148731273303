import React from 'react'
import { hooks } from '@front/volcanion/'
import _ from 'lodash'
import { Loader } from '@front/squirtle'

import moment from 'moment'
import { HookUtils } from '@front/volcanion/utils'

const withContainer = Component => ({ commercial_package_id, requestedAt, reason }) => {
  const [cp, state] = hooks.useModel('commercialpackage', [commercial_package_id], { single: true })
  const { min_delay, max_delay } = cp || {}
  const minimumDate = _.isFinite(min_delay) ? moment.utc().add(min_delay, 'minutes').format() : undefined
  const maximumDate = _.isFinite(max_delay) ? moment.utc().add(max_delay, 'minutes').format() : undefined
  const mergedProps = {
    minimumDate,
    maximumDate,
    requestedAt: !!requestedAt ? moment.utc(requestedAt).local() : moment(),
    reason
  }

  return (
    <Loader isLoading={HookUtils.getLoadingState(state)}>
      <Component {...mergedProps} />
    </Loader>
  )
}

export default withContainer
