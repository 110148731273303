
import React from 'react'
import { I18n, CDNSVG } from '@front/volcanion'

import { Grid, Stack } from '@mui/material'

import FormHeader from '@onix/elements/headers/form'
import PrimarySection from './Section/Primary'

const Detail = props => <>
  <Grid container justifyContent='center' alignItems='center' rowSpacing={3} sx={{ boxShadow: 6, borderRadius: 3, p: 2, pt: 0 }}>
    <Grid item xs={12}>
      <PrimarySection />
    </Grid>
  </Grid>
</>

const Contact = ({ isManagingRecord, props }) =>
  <Stack spacing={2}>
    <FormHeader
      isSvg
      icon={'contacts'}
      goBackPath={'/admin/contacts'}
      title={!!isManagingRecord ? I18n.t('contact.detail') : I18n.t('contact.create')}
    />
    <Detail />
  </Stack>

export default React.memo(Contact)
