import React, { useMemo } from 'react'
import { I18n } from '@front/volcanion'

import { TableCell, Typography } from '@mui/material'

import { Check, Close } from '@mui/icons-material'

const defaultTableCellProps = {
  align: 'center',
  size: 'small'
}

const defaultTypographyProps = {
  sx: {
    fontSize: 15,
    textAlign: 'center',
    color: 'text.label'
  }
}

const Row = ({ row, isSelected, ...props }) => {
  const status = useMemo(() =>
    I18n.t(`status.${!!_.get(row, 'deleted')
      ? 'archived'
      : !!_.get(row, 'active')
        ? 'active'
        : 'inactive'}`)
    , [])

  const statusColor = useMemo(() =>
    !!_.get(row, 'deleted')
      ? 'orange'
      : !!_.get(row, 'active')
        ? 'green'
        : 'red'
    , [])

  const deferred_billing = _.get(row, 'deferred_billing')
  return <>
    <TableCell {...defaultTableCellProps}>
      <Typography {...defaultTypographyProps}> {_.get(row, 'name')} </Typography> </TableCell>
    <TableCell {...defaultTableCellProps}>
      <Typography {..._.merge({}, defaultTypographyProps, { sx: { fontWeight: 600 } })}> {_.get(row, 'client_id')} </Typography>
    </TableCell>
    <TableCell {...defaultTableCellProps}>
      <Typography {..._.merge({}, defaultTypographyProps, { sx: { color: statusColor } })}> {status} </Typography>
    </TableCell>
    <TableCell {...defaultTableCellProps}>
      <Typography {...defaultTypographyProps}> {_.get(row, 'contact.email')} </Typography> </TableCell>
    <TableCell {...defaultTableCellProps}>
      <Typography {...defaultTypographyProps}> {_.get(row, 'password') ? <Check color='success' /> : <Close color='error' />}  </Typography>
    </TableCell>
    <TableCell {...defaultTableCellProps}>
      <Typography {...defaultTypographyProps}>{_.includes(['forbidden', 'allowed', 'mandatory'], deferred_billing) && I18n.t(`action.${deferred_billing}`)}</Typography>
    </TableCell>
  </>
}

export default React.memo(Row)
