import React, { useEffect } from 'react'

import { hooks } from '@front/volcanion'
import { Loader } from '@front/squirtle'

const withContainer = Component => props => {
  const isReady = hooks.useFormStatus('isReady')
  const [isManagingRecord] = hooks.useFormState('isManagingRecord')
  const [selectedCompany] = hooks.useGlobalState('selectedCompany')
  const navigate = hooks.useNavigate()

  useEffect(() => {
    !!isReady && navigate('/admin/contacts')
  }, [selectedCompany])

  const mergedProps = {
    isManagingRecord
  }

  return (
    <Loader isLoading={!isReady}>
      <Component {...mergedProps} />
    </Loader>
  )
}

export default withContainer
