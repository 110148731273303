
class PopupCallbacks {
  static submitAddressInfoPopupHandler(setFormValues, prefix) {
    return async function submitAddressInfoPopup(values, extra, meta, state) {
      const { passenger_name, passenger_number, resident_name } = values
      setFormValues([
        { field: `${prefix}.info.resident_name`, value: resident_name },
        { field: `${prefix}.info.passenger_name`, value: passenger_name },
        { field: `${prefix}.info.passenger_number`, value: passenger_number },
      ])

      return
    }
  }
  static openAddressInfoPopupHandler(openDialog, popupName, prefix) {
    return function openAddressInfoPopup() {
      openDialog(popupName, { prefix })
      return
    }
  }
}

export default PopupCallbacks
