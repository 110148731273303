import React from 'react'
import { I18n, CDNSVG } from '@front/volcanion'

import { Box, Collapse, Typography } from '@mui/material'
import { MenuSelectorField } from '@front/squirtle'

const SearchForm = ({ color, ...props }) => <>
  <Box sx={{ mb: 5 }}>
    <Collapse in timeout={1000}>
      <Box sx={{ display: 'flex', alignItems: 'center', p: 2, boxShadow: 6 }}>
        <Box>
          <CDNSVG name='contracts' fill={'primary.main'} category={'icons'}/>
        </Box>
        <Box sx={{ ml: 2 }}>
          <Typography sx={{ fontSize: 21, color: 'primary.main', letterSpacing: 1.5 }}> {I18n.t('companycontract.own')}: </Typography>
        </Box>
        <Box sx={{ ml: 2 }}>
          <MenuSelectorField
            name={'status'}
            options={[
              { label: I18n.t('status.active'), value: 'active' },
              { label: I18n.t('status.inactive'), value: 'inactive' },
              { label: I18n.t('status.archived'), value: 'archived' }
            ]}
            disableUnderline
            noneLabel={I18n.t('all')}
            valueDecoratorProps={{
              sx: {
                WebkitTextFillColor: color,
                fontWeight: 400,
                letterSpacing: 1.5,
                fontSize: 21,
                marginTop: '3px'
              }
            }}
          />
        </Box>
      </Box>
    </Collapse >
  </Box >
</>


export default React.memo(SearchForm)
