import React from 'react'
import { I18n, CDNSVG } from '@front/volcanion'

import { Box, Collapse, Grid, Typography } from '@mui/material'
import { TextField } from '@front/squirtle'

const SearchForm = props => <>
  <Collapse in timeout={1000}>
    <Box sx={{ alignItems: 'center', bgcolor: 'extraWhite', p: 2, boxShadow: 6 }}>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <CDNSVG name='contacts' fill={'primary.main'} category={'icons'} />
            <Box sx={{ ml: 2 }}>
              <Typography sx={{ fontSize: 21, color: 'primary.main', letterSpacing: 1.5 }}> {I18n.t('contact.label.own')}: </Typography>
            </Box>
          </Box>
        </Grid>
        <Grid item xs hidden={true}>
          <TextField name='test' />
        </Grid>
      </Grid>
    </Box>
  </Collapse >
</>



export default React.memo(SearchForm)
