import React, { useCallback } from 'react'

import { hooks } from '@front/volcanion'

const withContainer = Component => props => {
  const setFormValues = hooks.useFormChange()
  const [load_type] = hooks.useFieldValues(['load_type'])
  const PACKAGE_ENABLED = hooks.useRelayFeature('PACKAGE_ENABLED')

  const onLoadTypeChange = useCallback((e, value) => setFormValues([{ field: 'load_type', value: !!value ? 'package' : 'user' }]), [setFormValues])

  const mergedProps = {
    PACKAGE_ENABLED,
    onLoadTypeChange,
    load_package: load_type === 'package'
  }

  return (
    <Component {...mergedProps} {...props} />
  )
}

export default withContainer