import { I18n } from '@front/volcanion'

class PopupCallbacks {
  static submitPassengerPopupHandler(setFormValues, setFormState) {
    return async function submitPassengerPopup(values, extra, meta, state) {
      const { passenger_name, passenger_number, luggage = false, guest = false, companyuser_id, load_count } = values
      setFormState({ guest, companyuser: companyuser_id, cantBook: state?.cantBook })
      setFormValues([
        { field: 'load_count', value: load_count },
        { field: 'luggage', value: luggage },
        { field: 'source.info.passenger_name', value: passenger_name },
        { field: 'source.info.passenger_number', value: passenger_number },
        { field: 'passenger_name', value: passenger_name, },
        { field: 'passenger_number', value: passenger_number }
      ])
      return
    }
  }
  static openPassengerPopupHandler(openDialog) {
    return function openPassengerPopup() {
      openDialog('passenger_form')
      return
    }
  }
}

export default PopupCallbacks
