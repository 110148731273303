import React from 'react'
import { I18n, CDNSVG } from '@front/volcanion'

import { ArrowBackIosNew, ArrowForwardIos, Group } from '@mui/icons-material'

import { Tooltip, Box, IconButton, Grid, Typography } from '@mui/material'
import { TextField, SingleOptionSelectorField, ModelAutocompleteField, NumberField } from '@front/squirtle'
import { UserUtils } from '@front/volcanion/utils'

const InputLabelProps = {
  style: {
    fontSize: 22,
    letterSpacing: 1.5
  }
}

const Title = props => <Grid item xs={12}>
  <Box sx={{ borderLeft: 4, borderColor: 'primary.main' }}>
    <Typography variant={'h5'} color={'primary'} sx={{ p: 2, fontWeight: 300 }}>
      {I18n.t('passenger.label', { count: 1 })}
    </Typography>
  </Box>
</Grid>

const PassengerInfo = ({ normalizePhone, display_passenger_text, display_passenger_list, onCompanyuserChange, displayGuestField, disabledPassenger, booking_level, guest_booking, cantBook, getCompanyuserFilter, getCompanyuserValidate, companyuser_watchers, guest, onGuestChange, ...props }) =>
  <Grid item container xs={6} rowSpacing={2} hidden={!!cantBook}>
    <Grid item xs={12} hidden={!display_passenger_text}>
      <TextField
        name={'passenger_name'}
        label={I18n.t('passenger.label', { count: 1 })}
        InputLabelProps={InputLabelProps}
        disabled={disabledPassenger}
        required
      />
    </Grid>
    <Grid item xs={12} hidden={!display_passenger_list}>
      {!!display_passenger_list && <ModelAutocompleteField
        name='companyuser_id'
        label={I18n.t('passenger.label', { count: 1 })}
        model_name='companyuser'
        afterUserChange={onCompanyuserChange}
        searchMode='search'
        searchQuery={'startsWith'}
        autocompleteMutator={q => ({
          or: [
            { contact: { last_name: { startsWith: q } } },
            { contact: { first_name: { startsWith: q } } }
          ]
        })}
        labelKeys={['label']}
        config={{
          mutator: elem => {
            return ({
              label: UserUtils.getFormatedName(elem?.contact),
              ...elem
            })
          },
          populate: ['contact'],
          forced_filter: getCompanyuserFilter(...companyuser_watchers),
          validate_filter: getCompanyuserValidate,
          watchers: companyuser_watchers
        }}
        debounceTime={400}
        required
      />
      }
    </Grid>
    <Grid item xs={12}>
      <TextField
        name={'passenger_number'}
        label={I18n.t('gsm.label', { count: 1 })}
        InputLabelProps={InputLabelProps}
        disabled={disabledPassenger}
        normalize={normalizePhone}
        required
      />
    </Grid>
    <Grid item xs={12} hidden>
      <TextField
        name={'room_number'}
        label={I18n.t('room.number')}
        InputLabelProps={InputLabelProps}
      />
    </Grid>
    <Grid item xs={12} justifyContent='center' hidden={!displayGuestField}>
      <SingleOptionSelectorField
        name={'guest'}
        optionLabel={<Typography sx={{ fontSize: 16 }}> {I18n.t('order.guest_booking')}</Typography>}
        afterUserChange={onGuestChange}
        clearOnEmpty={false}
        getOptionLabelProps={() => ({ labelPlacement: 'end' })}
      />
    </Grid>
  </Grid >

const PassengerExtra = ({ onDecrementLoadCount, onIncrementLoadCount, load_count, identity, max_load, ...props }) =>
  <Grid item container xs={6}>
    <Grid item xs={3} />
    <Grid item xs={9}>
      <Box sx={{ display: 'flex' }}>
        <Box sx={{ color: 'primary.main' }}>
          <Group />
        </Box>
        <Typography sx={{ fontWeight: 200, ml: 1, color: 'text.label' }}>{I18n.t('passenger.number.label')}</Typography>
      </Box>
    </Grid>
    <Grid item container xs={12} justifyContent='center'>
      <Grid item xs={6}>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <IconButton sx={{ color: 'grayLight' }} onClick={onDecrementLoadCount}>
            <ArrowBackIosNew />
          </IconButton>
          <Box sx={{ ml: 1, mr: 1 }}>
            <NumberField
              name={'load_count'}
              type='number'
              minValue={0}
              maxValue={max_load}
              InputProps={{
                style: { textAlign: 'center' },
                inputProps: { style: { fontWeight: 200, fontSize: 22, textAlign: 'center' } }
              }}
            />
          </Box>
          <IconButton sx={{ color: 'grayLight' }} onClick={onIncrementLoadCount}>
            <ArrowForwardIos />
          </IconButton>
        </Box>
      </Grid>
    </Grid>
    <Grid item xs={3} />
    <Grid item container xs={9} >
      <Tooltip disableHoverListener={identity !== 'TV'} arrow followCursor title={I18n.t('luggage.restriction.TV')}>
        <SingleOptionSelectorField
          name={'luggage'}
          optionLabel={<Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
            <CDNSVG name='luggage' fill='primary.main' category={'icons'} />
            <Typography sx={{ ml: 1, mt: 1 }}> {I18n.t('luggage.label', { count: 2 })}</Typography>
          </Box>
          }
        />
      </Tooltip>
    </Grid>
  </Grid >

const PassengerPopup = props =>
  <Grid container spacing={2} rowSpacing={1.5} justifyContent='flex-end'>
    <Title />
    <PassengerInfo {...props} />
    <PassengerExtra {...props} />
  </Grid>


export default React.memo(PassengerPopup)
