import React from 'react'
import { I18n } from '@front/volcanion'

import { Divider, Grid, Typography } from '@mui/material'
import { TextField, OptionSelectorField } from '@front/squirtle'

const PoiAdditionalInfoForm = ({ meeting_point, name, origin, arrival, required }) =>
  <Grid container justifyContent='center' rowSpacing={2}>
    <Grid item xs={11}><Divider /></Grid>
    <Grid item xs={11}>
      <OptionSelectorField
        name='reservationinfo_type'
        label={I18n.t('address.pickup.location')}
        formLabelProps={{ sx: { fontSize: 17, mb: 1, color: 'primary.main' } }}
        getOptionLabelProps={() => ({
          componentsProps: { typography: { fontSize: 15 } }
        })}
        options={[
          { label: arrival, value: 'arrival' },
          { label: meeting_point, value: 'meeting_point' }
        ]}
        orientation={'vertical'}
        clearOnEmpty
        clearInvalid
        required
      />
    </Grid>
    <Grid item xs={11}><Divider /></Grid>
    <Grid item xs={11}>
      <Typography color={'primary'}>
        {I18n.t('meeting_point.pickup_info', {
          type: _.toUpper(I18n.t(required ? 'mandatory' : 'optional')),
          name,
          origin
        })}
      </Typography>
    </Grid>
    <Grid item xs={7}><TextField name='name' placeholder={`${name}${!!required ? '*' : ''}`} required={required} /></Grid>
    <Grid item xs={7}><TextField name='origin' placeholder={`${origin}${!!required ? '*' : ''}`} required={required} /></Grid>
    <Grid item xs={11}><Divider /></Grid>
  </Grid>



export default React.memo(PoiAdditionalInfoForm)
