import React from 'react'
import { hooks } from '@front/volcanion'

const withContainer = Component => props => {
  const [service] = hooks.useFieldValues(['service'])
  const [companyservice] = hooks.useModel('companyservice', [service], { single: true, populate: ['customerref_option'] })

  const manual_allowed = !service || _.get(companyservice, 'customerref_option.manual_allowed')
  const customerrefoption_id = _.get(companyservice, 'customerref_option.customerrefoption_id')
  const required = _.get(companyservice, 'customerref_option.fill_ref_rule') === 'required'

  const mergedProps = {
    customerrefoption_id,
    required,
    service,
    manual_allowed
  }

  return (
    <Component {...mergedProps} {...props} />
  )
}

export default withContainer
