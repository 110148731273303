import React, { useCallback } from 'react'
import { hooks } from '@front/volcanion'
import { Loader } from '@front/squirtle'

import Callbacks from './callbacks'

const withContainer = Component => props => {
  const [, { openDialog }] = hooks.useDialogs()
  const navigate = hooks.useNavigate()
  const setFormValues = hooks.useFormChange()
  const [, setTab] = hooks.useGlobalState('tab')
  const [source, setSourceDetails] = hooks.useFormState('details.source')
  const [{ schedule_type, load_count, ridemode_validation }, setFormState] = hooks.useFormState()

  const new_ridemode_id = ridemode_validation?.context?.data?.ridemode
  const [vehicle_count, commercial_package, requestedAt, service] = hooks.useFieldValues(['vehicle_count', 'commercial_package', 'requestedAt', 'service'])
  const [poi] = hooks.useSearchListenerResult('poi', 'source_poi')
  const [reservationinfo] = hooks.useModel('reservationinfo', [poi?.type?.reservationinfo], { single: true })
  const poi_status = hooks.useSearchListenerStatus('poi', 'source_poi')
  const packages = hooks.useMethodListenerResult('commercialpackage', 'packages_list')

  const submitPoiAdditionalInfoForm = useCallback(Callbacks.submitPoiAdditionalInfoFormHandler(setFormValues, poi, reservationinfo), [setFormValues, poi, reservationinfo])
  const onCloseResumeOrder = useCallback(() => {
    setTab(schedule_type === 'planned' ? 2 : 1)
    navigate('/profile')
  }, [schedule_type, setTab])

  const onCloseScheduleInformation = useCallback(() => setFormState({ unauthorized_schedule: false }), [setFormState])
  const onCloseTrafficAlertInformation = useCallback(() => setFormState({ traffic_alert: false }), [setFormState])

  const onCancelScheduleChange = useCallback(Callbacks.onCancelScheduleChangeHandler(setFormValues), [setFormValues])
  const onConfirmScheduleChange = useCallback(Callbacks.onConfirmScheduleChangeHandler(setFormValues, setFormState), [setFormValues, setFormState])

  const onCancelAddressChange = useCallback(Callbacks.onCancelAddressChangeHandler(setFormValues, setSourceDetails, source), [setFormValues, setSourceDetails, source])
  const onConfirmAddressChange = useCallback(Callbacks.onConfirmAddressChangeHandler(setFormValues, setSourceDetails), [setFormValues, setSourceDetails])
  const onSameAddress = useCallback(Callbacks.onSameAddressHandler(setFormValues), [setFormValues])

  const openIncompatible = useCallback(Callbacks.openIncompatibleHandler(openDialog, load_count, commercial_package), [openDialog, load_count, commercial_package])
  const onCancelIncompatible = useCallback(Callbacks.onCancelIncompatibleHandler(setFormValues), [setFormValues])
  const onConfirmIncompatible = useCallback(Callbacks.onConfirmIncompatibleHandler(setFormValues), [setFormValues])

  const onSubmitPackageFormPopup = useCallback(Callbacks.onSubmitPackageFormPopupHandler(packages, commercial_package, load_count, setFormValues, setFormState, openIncompatible, openDialog), [packages, commercial_package, load_count, setFormValues, setFormState, openIncompatible, openDialog])

  const onCloseInformationValidation = useCallback(() => {
    setFormValues([{ field: 'ridemode', value: new_ridemode_id }, { field: 'returnAt', value: undefined }])
  }, [setFormValues, new_ridemode_id])

  const mergedProps = {
    service,
    requestedAt,
    vehicle_count,
    reservationinfo,
    onCloseInformationValidation,
    onCloseResumeOrder,
    onCloseScheduleInformation,
    onCloseTrafficAlertInformation,
    submitPoiAdditionalInfoForm,
    onCancelScheduleChange,
    onConfirmScheduleChange,
    onCancelAddressChange,
    onConfirmAddressChange,
    onSameAddress,
    onCancelIncompatible,
    onConfirmIncompatible,
    onSubmitPackageFormPopup
  }

  return (
    <Loader isLoading={!_.get(poi_status, 'isReady')}>
      <Component {...mergedProps} />
    </Loader>
  )
}

export default withContainer
