import React from 'react'
import { I18n } from '@front/volcanion'

import { Box, Typography, Icon, Divider } from '@mui/material'

const UnauthorizedSchedule = ({ order_type, requestedAtFormatted }) =>
  <Box>
    <Box sx={{ display: 'flex', alignItems: 'flex-end', justifyContent: 'center' }}>
      <Box sx={{ fontSize: 33 }}><Icon fontSize='inherit' color='error'>{'warning'}</Icon></Box>
      <Typography variant={'h5'} ml={2}> {I18n.t('unauthorized_schedule.popup.title')} </Typography>
    </Box>

    <Divider sx={{ mt: 2, mb: 2 }} />

    <Typography variant={'h6'}>{I18n.t('unauthorized_schedule.popup.description', { order_type, requestedAt: requestedAtFormatted })}</Typography>

  </Box >

export default UnauthorizedSchedule
