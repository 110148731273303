import React, { useState } from 'react'
import { CDNSVG } from '@front/volcanion'

import { DateTimeCompactField } from '@front/squirtle'
import { Grid, Box, Icon, Typography, IconButton } from '@mui/material'

const IconDate = props => <CDNSVG name='edit' fill='primary.main' category={'icons'} {...props} />

const DateSection = ({ title, finalRequestedAt, immediateAllowed, ...rest }) => {
  const [open, setOpen] = useState(false)
  const [anchorEl, setAnchorEl] = useState(null)

  const handleClick = (e) => {
    !open && setOpen(true)
    setAnchorEl(e?.currentTarget)
  }

  return (
    <Grid item xs={12}>
      <Box
        display='flex'
        justifyContent='space-between'
        sx={{ '&:hover': { backgroundColor: 'white' }, bgcolor: 'extraWhite', p: 1, borderRadius: '4px', border: '1px solid', borderColor: 'rgba(61, 66, 94, 0.5)' }}>
        <Box> <Icon color='primary'>{'watch_later'}</Icon></Box>
        <Box>
          <Typography >{finalRequestedAt}</Typography>
        </Box>
        <IconButton onClick={handleClick} sx={{ p: 0, m: 0 }}>
          <IconDate />
        </IconButton>
        <Box hidden>
          <DateTimeCompactField
            onClose={(e) => { setOpen(false) }}
            open={open}
            compact
            disablePast
            PopperProps={{
              placement: "bottom-end",
              anchorEl: anchorEl
            }}
            componentsProps={{
              actionBar: { actions: _.compact([!!immediateAllowed && "today"]) },
            }}

            {...rest}
          />
        </Box>
      </Box>
    </Grid>
  )
}

export default React.memo(DateSection)
