import React from 'react'
import { I18n } from '@front/volcanion'

import { Grid, Icon, Box } from '@mui/material'
import { MenuSelectorField } from '@front/squirtle'

const RidemodeDuration = ({ options, ...props }) =>
  <Grid item xs={6} >
    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
      <Box sx={{ p: 1, mt: 2 }}>
        <Icon color='primary' mr={3}>{'timer'}</Icon>
      </Box>
      <MenuSelectorField
        label={I18n.t('ridemode.duration.label')}
        name={'ridemode_duration'}
        options={options}
        allowNone={false}
        selectFirst
      />
    </Box>
  </Grid>


export default React.memo(RidemodeDuration)
