import React from 'react'
import { I18n, CDNImage } from '@front/volcanion'

import { OptionSelectorComponent } from '@front/squirtle'
import { Typography, Box, Grid } from '@mui/material'
import { Input, ReservationInfo } from './'

import RideModeDuration from './RidemodeDuration'

const Title = ({ displayRoundTrip, stay_required, handleDisplayRoundTripChange }) =>
  <Grid item xs={12}>
    <Box sx={{ borderLeft: 4, p: 2, borderColor: 'primary.main', display: 'flex', alignItems: 'center' }}>
      <Typography variant={'h5'} mb={'3px'} color={'primary'} fontWeight={300}>
        {I18n.t('address.label', { count: -1 })}
      </Typography>
      <Box sx={{ ml: 1, display: !!stay_required ? 'flex' : 'none', alignItems: 'center' }}>
        <Typography mt={'2px'} color={!displayRoundTrip ? 'secondary.main' : 'text'}>{I18n.t('come.simple')}</Typography>
        <OptionSelectorComponent
          sx={{ display: 'flex', alignItems: 'flex-end', pt: 1 }}
          value={!!displayRoundTrip ? 'return' : null}
          onChange={handleDisplayRoundTripChange}
          formGroupProps={{ sx: { ml: 1, p: 1 } }}
          options={[{ label: I18n.t('come.back'), value: 'return' }]} valueType='switch'
          getOptionLabelProps={() => ({
            sx: {
              color: !!displayRoundTrip ? 'secondary.main' : 'text'
            }
          })}
          getOptionProps={() => ({
            sx: {
              "&.MuiSwitch-root .MuiSwitch-switchBase": {
                color: "secondary.main"
              },
              "&.MuiSwitch-root .MuiSwitch-track": {
                backgroundColor: 'secondary.main'
              }
            }
          })}
        />
      </Box>
    </Box>
  </Grid>

const AddressRow = ({ name, hidden, ...rest }) =>
  <Grid item xs={11} hidden={hidden}>
    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
      <Box sx={{ p: 1, mt: 2 }}>
        <CDNImage name={name} category='map' style={{ width: '30px', height: "30px" }} />
      </Box>
      <Input {...rest} />
    </Box>
  </Grid>

const AddressSection = ({
  stay_required,
  ALLOW_EMPTY_DESTINATION,
  destination_required,
  duration_required,
  displayRoundTrip,
  handleDisplayRoundTripChange,
  ...rest
}) =>
  <Grid container item xs={12}>
    <Box sx={{ borderRadius: 1, boxShadow: 3, bgcolor: 'extraWhite', mr: 1, pb: 3, width: 1 }}>
      <Grid container rowSpacing={1}>
        <Title handleDisplayRoundTripChange={handleDisplayRoundTripChange} displayRoundTrip={displayRoundTrip} stay_required={stay_required} />
        <AddressRow prefix='source' name={'source'} {...rest} />
        <ReservationInfo />
        {!!duration_required && <RideModeDuration />}
        <AddressRow
          name={'destination'}
          prefix='destination'
          required={!ALLOW_EMPTY_DESTINATION}
          hidden={!destination_required}
          {...rest} />
      </Grid>
    </Box>
  </Grid>

export default React.memo(AddressSection)
