import React from 'react'
import { I18n } from '@front/volcanion'

import moment from 'moment'

import { Box, Grid, Typography, IconButton } from '@mui/material/'
import { CheckCircle } from '@mui/icons-material/'

const Title = ({ order, ...props }) =>
  <Grid item xs={12}>
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <IconButton sx={{ fontSize: 56, color: 'green' }}>
        <CheckCircle fontSize={'inherit'} color={'inherit'} />
      </IconButton>
      <Typography color={'primary'} variant={'h5'} sx={{ fontWeight: 300, letterSpacing: 1.5 }}>
        {I18n.t(`order.confirmation.title.${order?.schedule_type}`)}
      </Typography>
    </Box>
  </Grid>

const PlannedComponent = ({ sourceFormatted, eta, order, requestedAtAdapted, ...props }) =>
  <Grid container item xs={12} rowSpacing={1}>
    <Grid item xs={12}>
      <Typography align={'center'}>{I18n.t(`order.confirmation.date.${order?.commercial_package?.load_type}`)}</Typography>
    </Grid>
    <Grid item xs={12}>
      <Typography color={'primary'} sx={{ fontWeight: 600, opacity: 0.8 }} align={'center'}>
        {!!eta ? I18n.t('order.confirmation.date_interval', {
          date: moment(requestedAtAdapted).format('DD/MM'),
          time: moment(requestedAtAdapted).format('HH[h]mm'),
          timeEnd: moment(requestedAtAdapted).add(eta, 'minutes').format('HH[h]mm')
        })
          : moment(requestedAtAdapted).format('DD/MM [à] HH[h]mm')
        }
      </Typography>
    </Grid>
    <Grid item xs={12}>
      <Typography align={'center'}>{I18n.t('prefix.at_bis')} </Typography>
    </Grid>
    <Grid item xs={12}>
      <Typography color={'primary'} sx={{ fontWeight: 600, opacity: 0.8 }} align={'center'}> {sourceFormatted} </Typography>
    </Grid>
  </Grid >

const ImmediateComponent = ({ sourceFormatted, order, ...props }) =>
  <Grid container item xs={12} rowSpacing={1}>
    <Grid item xs={12}>
      <Typography sx={{ fontWeight: 300 }} align={'center'}>
        {I18n.t(`order.confirmation.driver.meet.${order?.commercial_package?.load_type}`)}
      </Typography>
    </Grid>
    <Grid item xs={12}>
      <Typography color={'primary'} sx={{ fontWeight: 600, opacity: 0.8 }} align={'center'}>{sourceFormatted}</Typography>
    </Grid>
  </Grid>

const MeetingInformation = ({ order, order_reservationinfo, ...props }) =>
  <Grid container item xs={12} rowSpacing={1}>
    <Grid item xs={12}>
      <Typography align={'center'}> {I18n.t('meeting_point.label', { count: 1 })} : </Typography>
    </Grid>
    <Grid item xs={12}>
      <Typography color={'primary'} sx={{ fontWeight: 600, opacity: 0.8 }} align={'center'}> {order?.info?.meeting_point}</Typography>
    </Grid>
    <Grid item xs={12}>
      <Box sx={{ display: 'flex', justifyContent: 'center' }}>
        <Typography sx={{ mr: 1 }}> {order_reservationinfo?.reservationinfo?.name_translated} : </Typography>
        <Typography color={'primary'} sx={{ fontWeight: 600, opacity: 0.8 }} > {order_reservationinfo?.name}</Typography>
      </Box>
    </Grid>
    <Grid item xs={12}>
      <Box sx={{ display: 'flex', justifyContent: 'center' }}>
        <Typography sx={{ mr: 1 }}> {order_reservationinfo?.reservationinfo?.origin_translated} : </Typography>
        <Typography color={'primary'} sx={{ fontWeight: 600, opacity: 0.8 }} > {order_reservationinfo?.origin}</Typography>
      </Box>
    </Grid>
  </Grid>

const ResumeOrder = ({ hasReservationInfo, schedule_type, ...props }) => <>
  <Grid container spacing={1} rowSpacing={2}>
    <Title {...props} />
    <Grid item xs={12} hidden={schedule_type === 'immediate'}><PlannedComponent {...props} /></Grid>
    <Grid item xs={12} hidden={schedule_type === 'planned'}><ImmediateComponent {...props} /></Grid>
    <Grid item xs={12} hidden={!hasReservationInfo}><MeetingInformation {...props} /></Grid>
  </Grid>
</>

export default ResumeOrder
