import React from 'react'
import { I18n } from '@front/volcanion'

import { Loader } from '@front/squirtle'
import { Typography, Box, Grid } from '@mui/material'

import LoadingButton from '@mui/lab/LoadingButton'
import { ArrowRightAlt } from '@mui/icons-material'

const FooterSection = ({ estimation_type, isSubmitting, isLoading, price, onRequestSubmit, disabledSubmitButton }) =>
  <Grid container item xs={12} spacing={1} alignItems='center' justifyContent='flex-end'>
    <Grid item xs={7}>
      <Box sx={{ display: 'flex' }}>
        <Typography variant='h6' color={'primary'} sx={{ mr: 2, letterSpacing: 2, fontWeight: 400 }}>
          {I18n.t(`price.${estimation_type}.label.alter`)}
        </Typography>
        <Loader isLoading={isLoading} size={20}>
          <Typography variant='h6' color={'primary'} sx={{ fontWeight: 500 }}> {price} </Typography>
        </Loader>
      </Box>
    </Grid>
    <Grid item xs >
      <Box sx={{ display: 'flex', justifyContent: 'center' }}>
        <LoadingButton
          loading={!!isSubmitting}
          size='large'
          onClick={onRequestSubmit}
          color='primary'
          loadingIndicator={<Loader
            isLoading={!!isSubmitting}
            size={30}
          />}
          variant='contained'
          endIcon={!isSubmitting && <ArrowRightAlt sx={{ color: 'extraWhite' }} />}
          disabled={disabledSubmitButton}
        >
          {I18n.t('action.book')}
        </LoadingButton>
      </Box>
    </Grid>
  </Grid >

export default React.memo(FooterSection)
