import React, { useEffect } from 'react'
import { hooks } from '@front/volcanion'
import { Loader } from '@front/squirtle'

const withContainer = Component => ({ reservationinfo_id: inputReservationinfo_id }) => {
  const [reservationinfo_type] = hooks.useFieldValues(['reservationinfo_type'])
  const [reservationinfo_id] = hooks.useFormState('reservationinfo_id')
  const [reservationinfo, { isReady }] = hooks.useModel('reservationinfo', [inputReservationinfo_id || reservationinfo_id], { single: true })
  const [, setArrivalTrkey] = hooks.useFormState('arrival_trkey')
  const [poi] = hooks.useSearchListenerResult('poi', 'source_poi')
  const poi_status = hooks.useSearchListenerStatus('poi', 'source_poi')
  const meeting_point = _.get(poi, 'meeting_point_translated')

  const mergedProps = {
    meeting_point,
    required: reservationinfo_type === 'arrival',
    origin: reservationinfo?.origin_translated,
    name: reservationinfo?.name_translated,
    arrival: reservationinfo?.arrival_translated
  }

  useEffect(() => {
    !!reservationinfo && !!isReady && setArrivalTrkey(reservationinfo?.arrival_trkey)
  }, [isReady])

  return (<Loader isLoading={!isReady || !_.get(poi_status, 'isReady')}>
    <Component {...mergedProps} />
  </Loader>
  )
}

export default withContainer